import PropTypes from "prop-types";
import isString from "lodash/isString";
import { DropzoneOptions, FileRejection, useDropzone } from "react-dropzone";
import { SxProps, alpha, styled } from "@mui/material/styles";
import { Box, Paper, Stack, Typography } from "@mui/material";
//
import Image from "components/Image";
import { ReactNode } from "react";
import { fData } from "helpers/formatNumber";
import { UploadFileOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

// ----------------------------------------------------------------------

const DropZoneStyle = styled("div")(({ theme }) => ({
  outline: "none",
  overflow: "hidden",
  position: "relative",
  padding: theme.spacing(5, 1),
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create("padding"),
  backgroundColor: alpha(theme.palette.primary.main, 0.12),
  border: `1px dashed ${alpha(theme.palette.grey[500], 0.32)}`,
  "&:hover": { opacity: 0.82, cursor: "pointer" },
}));

export interface UploadFile extends File {
  preview?: string;
}

interface UploadProps extends DropzoneOptions {
  helperText?: ReactNode;
  file?: UploadFile | string;
  error?: boolean;
  sx?: SxProps;
}

export default function UploadSingleFile({
  error = false,
  file,
  helperText,
  sx,
  ...other
}: UploadProps) {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    fileRejections,
  } = useDropzone({
    multiple: false,
    ...other,
  });

  return (
    <Box sx={{ width: "100%", ...sx }}>
      <DropZoneStyle
        {...getRootProps()}
        sx={{
          mb: 2,
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            color: "error.main",
            borderColor: "error.light",
            bgcolor: "error.lighter",
          }),
        }}
      >
        <input {...getInputProps()} />

        <BlockContent />
      </DropZoneStyle>
      {file && (
        <Box
          component="iframe"
          src={isString(file) ? file : file.preview}
          sx={{
            borderRadius: 1,
            position: "relative",
            width: "100%",
            minHeight: 440,
            zIndex: 9,
          }}
        />
      )}

      {fileRejections.length > 0 && (
        <RejectionFiles fileRejections={fileRejections} />
      )}

      {helperText && helperText}
    </Box>
  );
}

function RejectionFiles({
  fileRejections,
}: {
  fileRejections: FileRejection[];
}) {
  return (
    <Paper
      variant="outlined"
      sx={{
        py: 1,
        px: 2,
        mt: 3,
        borderColor: "error.light",
        bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
      }}
    >
      {fileRejections.map(({ file, errors }) => {
        const { webkitRelativePath: path, size } = file;

        return (
          <Box key={path} sx={{ my: 1 }}>
            <Typography variant="subtitle2" noWrap>
              {path} - {fData(size)}
            </Typography>

            {errors.map((error: any) => (
              <Typography key={error.code} variant="caption" component="p">
                - {error.message}
              </Typography>
            ))}
          </Box>
        );
      })}
    </Paper>
  );
}

function BlockContent() {
  const { t } = useTranslation();
  return (
    <Stack
      spacing={2}
      alignItems="center"
      justifyContent="center"
      direction={{ xs: "column", md: "row" }}
      sx={{ width: 1, textAlign: { xs: "center", md: "left" } }}
    >
      <UploadFileOutlined sx={{ width: 190 }} />

      <Box sx={{ px: 3 }}>
        <Typography gutterBottom variant="h5">
          {t("form.btn.dropOrSelect")}
        </Typography>

        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          {t("form.btn.dropHere")}&nbsp;
          <Typography
            variant="body2"
            component="span"
            sx={{ color: "primary.main", textDecoration: "underline" }}
          >
            {t("form.btn.browse")}
          </Typography>
          &nbsp;{t("form.btn.throughtComputer")}
        </Typography>
      </Box>
    </Stack>
  );
}
