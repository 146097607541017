import { Alert, AlertTitle, Box, Container } from "@mui/material";
import LoadingScreen from "components/LoadingScreen";
import { IRole } from "helpers/interfaces";
import useAuth from "hooks/useAuth";
import { useLocation, Outlet, Navigate } from "react-router-dom";

interface AuthProp {
  allowedRoles?: IRole[];
}

const ProtectedRoutes = ({ allowedRoles }: AuthProp) => {
  const { AuthState } = useAuth();
  const location = useLocation();

  if (!AuthState.isInitialized) {
    return <LoadingScreen />;
  }

  if (!AuthState?.isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (!AuthState?.user?.active) {
    return (
      <Container sx={{ my: 8, minHeight: "27vh" }}>
        <Alert severity="error">
          <AlertTitle>Account Not active</AlertTitle>
          Your account is not active, please wait for the administrator to
          activate your account or contact us for more information.
        </Alert>
        <Box sx={{ height: "40hv", width: 2 }} />
      </Container>
    );
  }

  // no roles provided then check for authentification only
  if (!allowedRoles) {
    return <Outlet />;
  }

  // check if user has the required role
  if (AuthState?.user?.role && allowedRoles.includes(AuthState.user.role)) {
    return <Outlet />;
  }

  return (
    <Container>
      <Alert severity="error">
        <AlertTitle>Permission Denied</AlertTitle>
        You do not have permission to access this page
      </Alert>
    </Container>
  );
};

export default ProtectedRoutes;
