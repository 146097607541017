import type { Breakpoint, Theme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// ----------------------------------------------------------------------

export default function useResponsive(
  query: "up" | "down" | "between" | "only",
  key: Breakpoint,
  start?: number,
  end?: number
) {
  const mediaUp = useMediaQuery((theme: Theme) => theme.breakpoints.up(key));

  const mediaDown = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(key)
  );

  const mediaBetween = useMediaQuery((theme: Theme) =>
    theme.breakpoints.between(start || 0, end || 0)
  );

  const mediaOnly = useMediaQuery((theme: Theme) =>
    theme.breakpoints.only(key)
  );

  if (query === "up") {
    return mediaUp;
  }

  if (query === "down") {
    return mediaDown;
  }

  if (query === "between") {
    return mediaBetween;
  }

  if (query === "only") {
    return mediaOnly;
  }
  return null;
}
