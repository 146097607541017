function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = "/dashboard";

export const PATHS = {
  ROOTS_DASHBOARD: ROOTS_DASHBOARD,
  OFFERS: {
    ROOT: "/offers",
    SUBMIT: "/offers/submit",
  },
  DASHBOARD: {
    ROOT: ROOTS_DASHBOARD,
    OFFER: path(ROOTS_DASHBOARD, "/offer"),
    OFFERS: path(ROOTS_DASHBOARD, "/offers"),
    HOME: path(ROOTS_DASHBOARD, "/home"),
    ANALYTICS: path(ROOTS_DASHBOARD, "/analytics"),
    USERS: path(ROOTS_DASHBOARD, "/users"),
    CATEGORIES: path(ROOTS_DASHBOARD, "/categories"),
    REVIEWS: path(ROOTS_DASHBOARD, "/reviews"),
    PARTNERS: path(ROOTS_DASHBOARD, "/partners"),
    SUBMISSIONS: path(ROOTS_DASHBOARD, "/submissions"),
  },
  AUTH: {
    LOGIN: "/login",
    REGISTER: "/register",
    FORGOT_PASSWORD: "/forgot-password",
    RESET_PASSWORD: "/reset-password",
  },
  PROFILE: {
    ROOT: "/profile",
    SETTINGS: "/profile/settings",
  },
};
