import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

interface TableEmptyRowsProps {
  emptyRows: number;
  height?: number;
  colspan?: number;
}

export default function TableEmptyRows({
  emptyRows,
  height,
  colspan = 9,
}: TableEmptyRowsProps) {
  if (!emptyRows) {
    return null;
  }

  return (
    <TableRow
      sx={{
        ...(height && {
          height: height * emptyRows,
        }),
      }}
    >
      <TableCell colSpan={colspan} />
    </TableRow>
  );
}
