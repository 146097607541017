import { useState } from "react";

import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import { useTranslation } from "react-i18next";

// ----------------------------------------------------------------------

const LANGS = [
  {
    value: "en",
    label: "English",
    icon: "./icons/ic_flag_en.svg",
  },
  {
    value: "fr",
    label: "French",
    icon: "./icons/ic_flag_fr.svg",
  },
  {
    value: "de",
    label: "German",
    icon: "./icons/ic_flag_de.svg",
  },
  {
    value: "es",
    label: "Spanish",
    icon: "./icons/ic_flag_es.png",
  },
];

// ----------------------------------------------------------------------

export default function LanguagePopover() {
  const [open, setOpen] = useState<any>(null);
  const { i18n } = useTranslation();
  const currentLanguage = LANGS.find((lang) => lang.value === i18n.language);

  const handleOpen = (event: React.MouseEvent) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleChangeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    handleClose();
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          width: 20,
          height: 20,
          ...(open && {
            bgcolor: "action.selected",
          }),
        }}
      >
        <Box
          component="img"
          alt={currentLanguage?.label}
          src={currentLanguage?.icon}
          sx={{ width: 20, mr: 2 }}
        />
      </IconButton>

      <Popover
        open={!!open}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1,
            ml: 0.75,
            width: 180,
          },
        }}
      >
        {LANGS.map((option) => (
          <MenuItem
            key={option.value}
            selected={option.value === LANGS[0].value}
            onClick={() => handleChangeLanguage(option.value)}
            sx={{ typography: "body2", py: 1 }}
          >
            <Box
              component="img"
              alt={option.label}
              src={option.icon}
              sx={{ width: 20, mr: 2 }}
            />

            {option.label}
          </MenuItem>
        ))}
      </Popover>
    </>
  );
}
