// ==============================|| OVERRIDES - App BAR ||============================== //
import { Theme } from "@mui/material";
import { customShadows } from "theme/custom-shadows";

export default function AppBar(theme: Theme) {
  return {
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: customShadows().z8,
        },
      },
    },
  };
}
