import Box from "@mui/material/Box";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";

import { visuallyHidden } from "./utils";
import { MyTableColumnProps } from "./InternalTable";

interface HeadProps {
  order: "asc" | "desc" | undefined;
  orderBy: string;
  rowCount: number;
  headLabel: MyTableColumnProps[];
  numSelected?: number;
  onRequestSort: (event: React.MouseEvent, property: string) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function MyTableHead({
  order,
  orderBy,
  rowCount,
  headLabel,
  numSelected,
  onRequestSort,
  onSelectAllClick,
}: HeadProps) {
  const onSort = (property: string) => (event: any) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {numSelected === undefined ? null : (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
        )}

        {headLabel.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align || "left"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ width: headCell.width, minWidth: headCell.minWidth }}
          >
            {headCell.order ? (
              <TableSortLabel
                // hideSortIcon={orderBy === headCell.id && headCell.order}
                // active={headCell.order}
                active={orderBy === headCell.id && !!order}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={onSort(headCell.id)}
                sx={{
                  ...(orderBy === headCell.id && headCell.order
                    ? { color: "primary.main", fontWeight: "bold" }
                    : { color: "text.primary" }),
                }}
              >
                {headCell.label}
                {headCell.order ? (
                  <Box sx={{ ...visuallyHidden }}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
