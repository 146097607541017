import Router from "routes";
import ThemeCustomization from "theme";
import "simplebar-react/dist/simplebar.min.css";
function App() {
  return (
    <ThemeCustomization>
      <Router />
    </ThemeCustomization>
  );
}

export default App;
