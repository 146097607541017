// material-ui
import { alpha } from "@mui/material/styles";

// ==============================|| DEFAULT THEME - PALETTE  ||============================== //
function createGradient(color1: string, color2: string) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

// const PRIMARY = {
//   lighter: "#ffcbcb",
//   light: "#F0B054",
//   main: "#FDA525",
//   dark: "#887355",
//   darker: "#7A0930",
// };
export const PRIMARY = {
  lighter: "#A2B6F6",
  light: "#456CED",
  main: "#1034A6",
  dark: "#0B226F",
  darker: "#061138",
};
export const SECONDARY = {
  lighter: "#fff9d6",
  light: "#fff3ad",
  main: "#FACF5A",
  dark: "#af9e3f",
  darker: "#3b3516",
};
export const INFO = {
  lighter: "#D0F2FF",
  light: "#74CAFF",
  main: "#1890FF",
  dark: "#0C53B7",
  darker: "#04297A",
};
export const SUCCESS = {
  lighter: "#E9FCD4",
  light: "#AAF27F",
  main: "#54D62C",
  dark: "#229A16",
  darker: "#08660D",
};
export const WARNING = {
  lighter: "#FFF7CD",
  light: "#FFE16A",
  main: "#FFC107",
  dark: "#B78103",
  darker: "#7A4F01",
};
export const ERROR = {
  lighter: "#FFE7D9",
  light: "#FFA48D",
  main: "#FF4842",
  dark: "#B72136",
  darker: "#7A0C2E",
};

// const GREY = {
//   0: "#fff",
//   100: "#f4f4f4",
//   200: "#dcdcdc",
//   300: "#c4c4c4",
//   400: "#acacac",
//   500: "#919EAB",
//   600: "#7c7c7c",
//   700: "#646464",
//   800: "#4c4c4c",
//   900: "#343434",
//   500_8: alpha("#919EAB", 0.08),
//   500_12: alpha("#919EAB", 0.12),
//   500_16: alpha("#919EAB", 0.16),
//   500_24: alpha("#919EAB", 0.24),
//   500_32: alpha("#919EAB", 0.32),
//   500_48: alpha("#919EAB", 0.48),
//   500_56: alpha("#919EAB", 0.56),
//   500_80: alpha("#919EAB", 0.8),
// };
export const GREY = {
  0: "#FFFFFF",
  100: "#F9FAFB",
  200: "#F4F6F8",
  300: "#DFE3E8",
  400: "#C4CDD5",
  500: "#919EAB",
  600: "#466886",
  700: "#31495E",
  800: "#233443",
  900: "#0E151B",
  500_8: alpha("#919EAB", 0.08),
  500_12: alpha("#919EAB", 0.12),
  500_16: alpha("#919EAB", 0.16),
  500_24: alpha("#919EAB", 0.24),
  500_32: alpha("#919EAB", 0.32),
  500_48: alpha("#919EAB", 0.48),
  500_56: alpha("#919EAB", 0.56),
  500_80: alpha("#919EAB", 0.8),
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
};

const CHART_COLORS = {
  violet: ["#826AF9", "#9E86FF", "#D0AEFF", "#F7D2FF"],
  blue: ["#2D99FF", "#83CFFF", "#A5F3FF", "#CCFAFF"],
  green: ["#2CD9C5", "#60F1C8", "#A4F7CC", "#C0F2DC"],
  yellow: ["#FFE700", "#FFEF5A", "#FFF7AE", "#FFF3D6"],
  red: ["#FF6C40", "#FF8F6D", "#FFBD98", "#FFF2D4"],
};

export const COMMON = {
  common: { black: "#000", white: "#fff" },
  black: "#000",
  white: "#fff",
  primary: { ...PRIMARY, contrastText: "#fff" },
  secondary: { ...SECONDARY, contrastText: "#fff" },
  info: { ...INFO, contrastText: "#fff" },
  success: { ...SUCCESS, contrastText: GREY[800] },
  warning: { ...WARNING, contrastText: GREY[800] },
  error: { ...ERROR, contrastText: "#fff" },
  grey: GREY,
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  divider: GREY[500_24],
  action: {
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

const Palette = () => {
  return {
    palette: {
      light: {
        ...COMMON,
        mode: "light",
        text: { primary: GREY[800], secondary: GREY[600], disabled: GREY[500] },
        background: { paper: GREY[200], default: "#fff", neutral: GREY[200] },
        action: { active: GREY[600], ...COMMON.action },
      },
      dark: {
        ...COMMON,
        mode: "dark",
        text: { primary: "#fff", secondary: GREY[500], disabled: GREY[600] },
        background: {
          paper: GREY[800],
          default: GREY[900],
          neutral: GREY[500_16],
        },
        action: { active: GREY[500], ...COMMON.action },
      },
    },
  };
};

export default Palette;
