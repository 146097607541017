import SvgColor from "components/svg-color";
import { INavList } from "helpers/consts";
import { PATHS } from "routes/paths";

import WorkRoundedIcon from "@mui/icons-material/WorkRounded";
import RecentActorsRoundedIcon from "@mui/icons-material/RecentActorsRounded";
import PeopleRoundedIcon from "@mui/icons-material/PeopleRounded";
import { CategoryRounded, ReviewsRounded } from "@mui/icons-material";
import TurnedInIcon from "@mui/icons-material/TurnedIn";

const navConfig: INavList = [
  {
    title: "Offers",
    path: "",
    children: [
      {
        title: "Create Offer",
        path: PATHS.DASHBOARD.OFFER,
        icon: <WorkRoundedIcon />,
      },
      {
        title: "View Offers",
        path: PATHS.DASHBOARD.OFFERS,
        icon: <RecentActorsRoundedIcon />,
      },
    ],
  },
  {
    title: "Manage categories",
    path: "",
    children: [
      {
        title: "Categories",
        path: PATHS.DASHBOARD.CATEGORIES,
        icon: <CategoryRounded />,
      },
    ],
  },
  {
    title: "Manage users",
    path: "",
    children: [
      {
        title: "Users",
        path: PATHS.DASHBOARD.USERS,
        icon: <PeopleRoundedIcon />,
      },
    ],
  },
  {
    title: "Manage Reviews",
    path: "",
    children: [
      {
        title: "Reviews",
        path: PATHS.DASHBOARD.REVIEWS,
        icon: <ReviewsRounded />,
      },
    ],
  },
  {
    title: "Manage Partners",
    path: "",
    children: [
      {
        title: "Partners",
        path: PATHS.DASHBOARD.PARTNERS,
        icon: <PeopleRoundedIcon />,
      },
    ],
  },
  {
    title: "Manage Submissions",
    path: "",
    children: [
      {
        title: "User Submissions",
        path: PATHS.DASHBOARD.SUBMISSIONS,
        icon: <TurnedInIcon />,
      },
    ],
  },
];

export default navConfig;
