import {
  CssBaseline,
  Theme,
  ThemeProvider,
  createTheme,
  ThemeOptions,
} from "@mui/material";
import { StyledEngineProvider } from "@mui/system";
import { PropsWithChildren, useMemo } from "react";
import ComponentsOverrides from "./overrides";
import Palette from "./palette";
import Typography from "./typography";
import { shadows } from "./shadows";

function ThemeCustomization({ children }: PropsWithChildren) {
  const theme = Palette();
  const themeTypography = Typography(`'Public Sans','Poppins', sans-serif`);

  const themeOptions = useMemo(
    () => ({
      palette: theme.palette.light,
      typography: themeTypography,
      shadows: shadows(),
      shape: { borderRadius: 8 },
    }),
    [theme, themeTypography]
  );

  const themes = createTheme(themeOptions as ThemeOptions);
  themes.components = ComponentsOverrides(themes as Theme);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default ThemeCustomization;
