// ----------------------------------------------------------------------

import { Theme, alpha } from "@mui/material";
import { customShadows } from "theme/custom-shadows";

export default function Card(theme: Theme) {
  return {
    MuiCard: {
      variants: [
        {
          props: { variant: "outlined" },
          style: { borderColor: alpha(theme.palette.grey[500], 0.32) },
        },
      ],
      styleOverrides: {
        root: {
          position: "relative",
          boxShadow: customShadows().card,
          borderRadius: Number(theme.shape.borderRadius) * 4,
          zIndex: 0, // Fix Safari overflow: hidden with border radius
          p: 1,
          border: `1px solid ${alpha(theme.palette.grey[500], 0.1)}`,
          backgroundColor: theme.palette.grey[100],
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: { variant: "h6" },
        subheaderTypographyProps: {
          variant: "body2",
          marginTop: theme.spacing(0.5),
        },
      },
      styleOverrides: {
        root: {
          padding: theme.spacing(3, 3, 0),
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: theme.spacing(3),
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          paddingLeft: theme.spacing(3),
          paddingBottom: theme.spacing(2),
        },
      },
    },
  };
}
