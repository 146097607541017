// ==============================|| OVERRIDES - INPUT LABEL ||============================== //
import { Theme, alpha } from "@mui/material";

export default function Container(theme: Theme) {
  return {
    MuiContainer: {
      styleOverrides: {
        root: {
          backgroundColor: alpha(theme.palette.grey[100], 0.22),
        },
      },
    },
  };
}
