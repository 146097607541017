import { memo, useState } from "react";
// @mui
import {
  AppBar,
  Box,
  Container,
  Drawer,
  IconButton,
  Stack,
  Toolbar,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Logo from "components/Logo";
import LanguagePopover from "layouts/header/languagePopover";
import AccountPopover from "layouts/header/AccountPopover";
import useResponsive from "hooks/useResponsive";
import { styled } from "@mui/system";
import { Link } from "react-router-dom";
import { INavItem, sampleNavItems } from "helpers/consts";
import { Restricted } from "components/RolesManagement/Restricted";
import { useTranslation } from "react-i18next";

function NavbarHorizontal() {
  const matchesXs = useResponsive("down", "sm");

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <AppBar component="nav" sx={{ bgcolor: "background.neutral" }}>
      <Toolbar>
        <Container>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderRadius: 1,
              px: 0.5,
            }}
          >
            <Stack direction={"row"} spacing={4}>
              <Logo withTitle />
            </Stack>

            {!matchesXs ? (
              <>
                <Stack direction="row" spacing={4} sx={{ py: 1 }}>
                  {sampleNavItems.map((item, index) => (
                    <Restricted key={index} to={item.restricted}>
                      <NavItem item={item} />
                    </Restricted>
                  ))}
                </Stack>

                <Stack direction="row" alignItems="center" spacing={1}>
                  <LanguagePopover />
                  <AccountPopover />
                </Stack>
              </>
            ) : (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2 }}
              >
                <MenuIcon color="primary" />
              </IconButton>
            )}
            {matchesXs && (
              <NavDrawer
                handleDrawerToggle={handleDrawerToggle}
                open={mobileOpen}
              >
                {sampleNavItems.map((item, index) => (
                  <Restricted key={index} to={item.restricted}>
                    <Box key={index} sx={{ p: 2 }}>
                      <NavItem item={item} />
                    </Box>
                  </Restricted>
                ))}

                <Stack direction={"row"} sx={{ ml: 1.5, p: 2 }}>
                  <LanguagePopover />
                </Stack>

                <Stack sx={{ p: 2 }} direction={"row"}>
                  <AccountPopover />
                </Stack>
              </NavDrawer>
            )}
          </Box>
        </Container>
      </Toolbar>
    </AppBar>
  );
}

export default memo(NavbarHorizontal);

const NavItem = ({ item }: { item: INavItem }) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{ display: "flex", alignItems: "center", textDecoration: "none " }}
      component={Link}
      to={item.path}
    >
      <Typography
        variant="body2"
        sx={{
          color: "text.primary",
          transition: "color 0.3s ease",
          fontWeight: 500,
          "&:hover": { color: "primary.main", fontWeight: 600 },
          cursor: "pointer",
        }}
      >
        {/* {item.title} */}
        {t("navbar." + item.title)}
      </Typography>
    </Box>
  );
};

const DrawerHeaderStyled = styled(Box)(({ theme }) => ({
  // ...theme.mixins,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundImage: ` linear-gradient(${alpha(
    theme.palette.background.default,
    0.8
  )}, ${alpha(theme.palette.background.paper, 1)})`,
  borderRadius: "0 0 10px 10px",
}));

interface DrawerProps {
  handleDrawerToggle(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void;
  open: boolean;
  children: React.ReactNode;
}

function NavDrawer({ handleDrawerToggle, open, children }: DrawerProps) {
  const theme = useTheme();

  return (
    <Drawer
      variant="temporary"
      anchor="right"
      open={open}
      onClose={handleDrawerToggle}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      sx={{
        "& .MuiDrawer-paper": {
          boxSizing: "border-box",
          width: 300,
          borderLeft: `1px solid ${theme.palette.divider}`,
          backgroundImage: "none",
          boxShadow: "inherit",
        },
      }}
    >
      <DrawerHeaderStyled theme={theme}>
        <Stack direction="row" spacing={1} alignItems="start" sx={{ p: 2 }}>
          <Logo />
        </Stack>
      </DrawerHeaderStyled>
      {children}
    </Drawer>
  );
}
