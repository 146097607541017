// @mui
import { Box, BoxProps, SxProps } from "@mui/material";
import { Effect, LazyLoadImage } from "react-lazy-load-image-component";

interface ImageProps extends BoxProps {
  disabledEffect?: boolean;
  removeWrapper?: boolean;
  effect?: Effect;
  sx?: SxProps;
  alt?: string;
  src?: string;
}

export default function Image({
  disabledEffect = false,
  removeWrapper = false,
  effect = "blur",
  sx,
  ...BoxProps
}: ImageProps) {
  const placeholderSrc = "/img-paceholder.png";

  if (removeWrapper) {
    return (
      <Box
        component={LazyLoadImage}
        wrapperClassName="wrapper"
        effect={disabledEffect ? undefined : effect}
        placeholderSrc={placeholderSrc}
        sx={{ width: 1, height: 1, objectFit: "cover", ...sx }}
        {...BoxProps}
      />
    );
  }

  return (
    <Box
      component="span"
      sx={{
        lineHeight: 0,
        display: "block",
        overflow: "hidden",
        "& .wrapper": {
          width: 1,
          height: 1,
          backgroundSize: "cover !important",
        },
        ...sx,
      }}
    >
      <Box
        component={LazyLoadImage}
        wrapperClassName="wrapper"
        effect={disabledEffect ? undefined : effect}
        placeholderSrc={placeholderSrc}
        sx={{ width: 1, height: 1, objectFit: "cover" }}
        {...BoxProps}
      />
    </Box>
  );
}
