import { LoadingButton } from "@mui/lab";
import { Box, Button, Stack, Typography } from "@mui/material";
import { FUploadSingleFile } from "components/form/FUpload";
import { UploadFile } from "components/upload/UploadSingleFile";
import { useFormik, FormikProvider, Form } from "formik";
import { fData } from "helpers/formatNumber";

import useFetch from "hooks/useFetch";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { API } from "utils/axios/paths";

const UploadResume = ({
  initialResumeUrl,
  onChanged,
  noActions = false,
}: {
  initialResumeUrl: string | undefined;
  onChanged?: (file?: UploadFile, saved?: boolean) => void;
  noActions?: boolean;
}) => {
  const { call: uploidResume } = useFetch("post", API.USER.RESUME);
  const [changed, setChanged] = useState(false);
  const [err, setErr] = useState(false);
  const { t } = useTranslation();
  const formik = useFormik({
    initialValues: {
      resume: initialResumeUrl
        ? {
            preview: initialResumeUrl,
            ...new File([], "resume"),
          }
        : undefined,
    },
    onSubmit: async (values, { resetForm }) => {
      const formData = new FormData();

      if (values.resume && values.resume instanceof File) {
        formData.append("resume", values.resume);
        try {
          await uploidResume({
            body: formData,
            moreOptions: {
              headers: { "Content-Type": "multipart/form-data" },
            },
          });
          onChanged && onChanged(formik.values.resume, true);
          resetForm();
        } catch (error) {
          setErr(true);
        }
      }
    },
  });

  useEffect(() => {
    if (
      (initialResumeUrl &&
        initialResumeUrl.split("?")[0] !==
          formik.values.resume?.preview?.split("?")[0]) ||
      (!initialResumeUrl && !!formik.values.resume?.preview)
    ) {
      setChanged(true);
      onChanged && onChanged(formik.values.resume, false);
    }
  }, [formik.values.resume?.preview]);

  return (
    <FormikProvider value={formik}>
      <Form noValidate onSubmit={formik.handleSubmit}>
        <Stack spacing={1} alignItems={"center"}>
          {err && (
            <Typography variant="caption" color="error">
              {t("form.errors.errorUploadingResume")}
            </Typography>
          )}
          <FUploadSingleFile
            name="resume"
            accept={{
              "image/*": [".png", ".jpeg", ".jpg"],
              "application/pdf": [".pdf"],
            }}
            maxSize={3145728}
            helperText={
              <Typography
                variant="caption"
                sx={{
                  mt: 2,
                  mx: "auto",
                  display: "block",
                  textAlign: "center",
                  color: "text.secondary",
                }}
              >
                {t("form.allowed")} *.pdf, *.jpeg, *.jpg, *.png
                <br /> {t("form.maxSize")} {fData(3145728)}
              </Typography>
            }
          />
          {!noActions && (
            <Box>
              <LoadingButton
                disabled={formik.isSubmitting || !changed}
                type="submit"
                variant="contained"
                loading={formik.isSubmitting}
              >
                {initialResumeUrl && !formik.values.resume
                  ? t("form.btn.saved")
                  : t("form.btn.savePhoto")}
              </LoadingButton>
              <Button
                variant="outlined"
                sx={{ ml: 1 }}
                onClick={() => formik.resetForm()}
                color="error"
                disabled={formik.isSubmitting || !changed}
              >
                {t("form.btn.cancel")}
              </Button>
            </Box>
          )}
        </Stack>
      </Form>
    </FormikProvider>
  );
};

export default UploadResume;
