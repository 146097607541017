import { useEffect } from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Drawer from "@mui/material/Drawer";

import { alpha } from "@mui/material/styles";
import ListItemButton from "@mui/material/ListItemButton";

import { usePathname } from "hooks/use-pathname";
import useResponsive from "hooks/useResponsive";

import Logo from "components/Logo";
import Scrollbar from "components/scrollbar";
import navConfig from "./nav-config";
import { INavItem } from "helpers/consts";
import { Link } from "react-router-dom";
import { NAV } from "layouts/dashborad/config-layout";
import { Divider, ListItemIcon, Typography } from "@mui/material";
import { Restricted } from "components/RolesManagement/Restricted";

interface NavProps {
  openNav: boolean;
  onCloseNav: () => void;
}

export default function Nav({ openNav, onCloseNav }: NavProps) {
  const pathname = usePathname();

  const upLg = useResponsive("up", "lg");

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderMenu = (
    <Stack component="nav" spacing={0.5} sx={{ px: 2 }}>
      {navConfig.map((item) => (
        <Restricted key={item.title} to={item.restricted}>
          <NavItem item={item} />
        </Restricted>
      ))}
    </Stack>
  );

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Logo sx={{ my: 3, ml: 4 }} />

      {renderMenu}

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <Box
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.WIDTH },
        bgcolor: "background.paper",
      }}
    >
      {upLg ? (
        <Box
          sx={{
            height: 1,
            position: "fixed",
            width: NAV.WIDTH,
            borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          }}
        >
          {renderContent}
        </Box>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          PaperProps={{
            sx: {
              width: NAV.WIDTH,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}

function NavItem({ item }: { item: INavItem }) {
  const pathname = usePathname();

  const active = item.path === pathname;

  if (item?.children) {
    return (
      <>
        <Stack
          spacing={1}
          direction="row"
          width="100%"
          alignItems="center"
          mx={3}
        >
          <Stack
            spacing={0.5}
            direction="row"
            sx={{
              typography: "caption",
              color: "grey.500",
              textTransform: "capitalize",
              fontWeight: "fontWeightMedium",
            }}
          >
            <Box component="span" sx={{ width: 20, height: 20, mr: 2 }}>
              {item.icon}
            </Box>

            <Box component="span">{item.title} </Box>
          </Stack>
          <Box sx={{ flexGrow: 1, borderBottom: 1, borderColor: "grey.300" }} />
        </Stack>
        {item.children.map((child) => (
          <ListItemButton
            key={child.title}
            component={Link}
            to={child.path}
            sx={{
              minHeight: 44,
              borderRadius: 0.75,
              typography: "body2",
              color: "text.secondary",
              textTransform: "capitalize",
              fontWeight: "fontWeightMedium",
              ...(child.path === pathname && {
                color: "primary.main",
                fontWeight: "fontWeightSemiBold",
                bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
                "&:hover": {
                  bgcolor: (theme) => alpha(theme.palette.primary.main, 0.16),
                },
              }),
            }}
          >
            <ListItemIcon>{child.icon}</ListItemIcon>

            <Box component="span">{child.title} </Box>
          </ListItemButton>
        ))}
      </>
    );
  }

  return (
    <ListItemButton
      component={Link}
      to={item.path}
      sx={{
        minHeight: 44,
        borderRadius: 0.75,
        typography: "body2",
        color: "text.secondary",
        textTransform: "capitalize",
        fontWeight: "fontWeightMedium",
        ...(active && {
          color: "primary.main",
          fontWeight: "fontWeightSemiBold",
          bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
          "&:hover": {
            bgcolor: (theme) => alpha(theme.palette.primary.main, 0.16),
          },
        }),
      }}
    >
      <ListItemIcon>{item.icon}</ListItemIcon>

      <Box component="span">{item.title} </Box>
    </ListItemButton>
  );
}
