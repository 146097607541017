import axios from "axios";
// config
import { HOST_API } from "config";
import { API } from "./paths";
import storageConfig from "utils/storage";

// ----------------------------------------------------------------------

export const client = axios.create({
  baseURL: HOST_API,
  timeout: 180000, //Timout 3 min
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

const withoutBearer = [API.AUTH.LOGIN, API.AUTH.REGISTER];

client.interceptors.request.use(
  (config) => {
    const token = storageConfig.getToken();

    if (
      token !== null &&
      !withoutBearer.includes(config.url || "") &&
      config &&
      config.headers
    ) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (err) => Promise.reject(err)
);

client.interceptors.response.use(
  (response) => response,
  (err) => {
    console.log(err);

    if (err.response?.status === 401) {
      storageConfig.clear();
    }
    throw (
      (err?.response && err.response?.data) || new Error("Something went wrong")
    );
  }
);

export const CancelToken = axios.CancelToken;

export const fetcher = (
  resource: string | { url: string; params: object } | [string, object]
) => {
  let url = "";
  let params = {};

  if (typeof resource === "string") {
    url = resource;
  } else if (Array.isArray(resource)) {
    url = resource[0];
    params = resource[1];
  } else {
    url = resource.url;
    params = resource.params;
  }

  return client
    .get(url, { params })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};
export default client;
