import {
  Alert,
  Breadcrumbs,
  Button,
  ButtonBaseProps,
  ButtonProps,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Modal,
  Stack,
} from "@mui/material";
import { Container } from "@mui/system";
import LoadingScreen from "components/LoadingScreen";
import useResume from "hooks/swr/useResume";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router";
import useSWR from "swr";
import { API } from "utils/axios/paths";
import { Link as RouterLink } from "react-router-dom";
import { Link, Typography } from "@mui/material";
import { PATHS } from "routes/paths";
import { IOffer } from "helpers/interfaces";
import UploadResume from "components/user/UploadResume";
import { useEffect, useState } from "react";
import { CloseRounded } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import useFetch from "hooks/useFetch";
import { FUploadSingleFile } from "components/form/FUpload";
import { on } from "events";

export default function SubmitOffer() {
  let { OfferId } = useParams();
  const [uploadedResume, setUploadedResume] = useState<File | undefined>(
    undefined
  );

  const {
    data: offerData,
    error,
    isLoading,
  } = useSWR(`${API.OFFERS}/${OfferId}`);
  const offer = offerData as IOffer;

  const { data: resume, isLoading: resumeLoading, mutate } = useResume();

  const [canContinue, setCanContinue] = useState(!!resume);

  if (error)
    return (
      <Container>
        <Alert severity="error">Something went wrong, please try again</Alert>
      </Container>
    );
  if (isLoading || resumeLoading) return <LoadingScreen />;

  console.log("uploaded", uploadedResume, "resume", resume);

  return (
    <>
      <Helmet>
        <title> Submitting for {offer.name}</title>
      </Helmet>
      <Container>
        <Breadcrumbs aria-label="breadcrumb" sx={{ my: 3 }}>
          <Link component={RouterLink} underline="hover" color="inherit" to="/">
            Home
          </Link>
          <Link
            component={RouterLink}
            underline="hover"
            color="inherit"
            to={PATHS.OFFERS.ROOT}
          >
            Offers
          </Link>
          <Typography color="text.primary">{offer?.name}</Typography>
        </Breadcrumbs>

        <Card sx={{ borderColor: "primary.dark" }} variant="outlined">
          <CardContent>
            <Grid container spacing={2} alignItems={"center"}>
              <Grid item xs={12} md={6}>
                <Typography variant="h3">Submit for {offer.name}</Typography>
                <Typography variant="body1">
                  you can upload a new resume or use the existing one
                </Typography>
                <Stack my={3}>
                  {!resume && !uploadedResume && (
                    <Alert severity="warning">
                      error loading resume, please upload a new one
                    </Alert>
                  )}
                  <Stack spacing={2} direction={"row"}>
                    <SubmitOfferBtn
                      variant="contained"
                      disabled={!canContinue}
                      offerId={offer.id}
                      newResume={uploadedResume}
                    >
                      Continue
                    </SubmitOfferBtn>

                    <Button variant="outlined">
                      <Link
                        component={RouterLink}
                        to={PATHS.OFFERS.ROOT + "/" + offer.id}
                      >
                        Go back
                      </Link>
                    </Button>
                  </Stack>
                </Stack>
                {/* </div> */}
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                // sx={{ bgcolor: "grey.300" }}
                justifyContent={"center"}
              >
                <UploadResume
                  initialResumeUrl={resume}
                  onChanged={(file, isChanged) => {
                    setUploadedResume(file);
                    setCanContinue(true);
                  }}
                  noActions={true}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </>
  );
}

interface SubmitOfferBtnProps extends ButtonProps {
  offerId: string;
  newResume?: File;
}

const SubmitOfferBtn = ({
  onClick,
  newResume,
  offerId,
  ...others
}: SubmitOfferBtnProps) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const handleClose = () => setOpen(false);

  const {
    call: submitOffer,
    isLoading: isSabmitting,
    error: submitError,
  } = useFetch("post", API.SUBMIT_OFFER(offerId));

  const {
    call: uploidResume,
    error: UploadError,
    isLoading: uploadLoading,
  } = useFetch("post", API.USER.RESUME);

  const onSubmit = async () => {
    if (newResume) {
      const formData = new FormData();
      formData.append("resume", newResume);
      await uploidResume({
        body: formData,
        moreOptions: {
          headers: { "Content-Type": "multipart/form-data" },
        },
      });
    }
    await submitOffer({});
    navigate(PATHS.OFFERS.ROOT);
  };

  const error = submitError || UploadError;
  const isLoading = isSabmitting || uploadLoading;
  return (
    <>
      <Button
        onClick={async (e) => {
          try {
            onClick && (await onClick(e));
            setOpen(true);
          } catch (error) {
            // console.error(error);
          }
        }}
        variant="contained"
        {...(others as ButtonProps)}
      >
        Continue
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
          }}
        >
          <CardHeader
            title="Confirm Submission"
            action={
              <Button onClick={handleClose}>
                <CloseRounded />
              </Button>
            }
          />
          <CardContent>
            {!!newResume && (
              <Alert severity="info">
                new resume uploaded, your profile will be updated automatically
                to use this resume.
              </Alert>
            )}
            <Typography variant="body1">
              Continue to submit for this offer?
            </Typography>
            {error && (
              <Alert severity="error">
                Something went wrong, please try again
              </Alert>
            )}
          </CardContent>
          <CardActions>
            <LoadingButton
              variant="contained"
              disabled={isLoading}
              loading={isLoading}
              size="large"
              onClick={onSubmit}
            >
              Submit
            </LoadingButton>
          </CardActions>
        </Card>
      </Modal>
    </>
  );
};
