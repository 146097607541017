import { Theme, alpha } from "@mui/material";

export default function Paper(theme: Theme) {
  return {
    MuiPaper: {
      variants: [
        {
          props: { variant: "outlined" },
          style: { borderColor: alpha(theme.palette.grey[500], 0.12) },
        },
      ],
      styleOverrides: {
        root: {
          backgroundImage: "none",
          backgroundColor: theme.palette.grey[200],
        },
      },
    },
  };
}
