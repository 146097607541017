import { useState } from "react";
import Box from "@mui/material/Box";

import { HEADER, NAV } from "./config-layout";
import Nav from "./NavBarVertical";
import useResponsive from "hooks/useResponsive";
import Header from "./header";

// ----------------------------------------------------------------------
const SPACING = 8;

export default function DashboardLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const [openNav, setOpenNav] = useState(false);
  const lgUp = useResponsive("up", "lg");

  return (
    <>
      <Header onOpenNav={() => setOpenNav(true)} />

      <Box
        sx={{
          minHeight: 1,
          display: "flex",
          flexDirection: { xs: "column", lg: "row" },
          bgcolor: "background.paper",
          ...(lgUp && {
            minHeight: "100vh",
          }),
        }}
      >
        <Nav openNav={openNav} onCloseNav={() => setOpenNav(false)} />

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            minHeight: 1,
            display: "flex",
            flexDirection: "column",
            py: `${HEADER.H_MOBILE + SPACING}px`,
            ...(lgUp && {
              px: 2,
              py: `${HEADER.H_DESKTOP + SPACING}px`,
              width: `calc(100% - ${NAV.WIDTH}px)`,
            }),
          }}
        >
          {children}
        </Box>
      </Box>
    </>
  );
}
