// import { verify, sign } from "jsonwebtoken";
//
import axiosInstance from "./axios";
import { jwtDecode } from "jwt-decode";
import storageConfig from "./storage";

// ----------------------------------------------------------------------

const isValidToken = (accessToken: string | null | undefined): boolean => {
  if (!accessToken) {
    return false;
  }
  const decoded: { exp: number } = jwtDecode(accessToken);
  const currentTime: number = Date.now() / 1000;

  return decoded.exp > currentTime;
};
// handle when the token is expired

const setSession = (accessToken: string | null | undefined) => {
  if (accessToken) {
    storageConfig.setToken(accessToken);
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    //handle when the token is expired
  } else {
    storageConfig.clear();
    delete axiosInstance.defaults.headers.common.Authorization;
  }
};

export { isValidToken, setSession };
