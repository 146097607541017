// ==============================|| OVERRIDES - INPUT LABEL ||============================== //
import { Theme } from "@mui/material";

export default function InputLabel(theme: Theme) {
  return {
    MuiInputLabel: {
      styleOverrides: {
        root: {},
        outlined: {
          "&.MuiInputLabel-sizeSmall": {},
          "&.MuiInputLabel-shrink": {},
        },
      },
    },
  };
}
