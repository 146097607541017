import Tooltip from "@mui/material/Tooltip";
import Toolbar from "@mui/material/Toolbar";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import { SearchRounded } from "@mui/icons-material";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { useState } from "react";

type FilterActiveType = "active" | "all" | "inactive";
interface OfferTableToolbarProps {
  filterName: string;
  filterActive: FilterActiveType;
  onFilterActive: (filter: FilterActiveType) => void;
  onFilterName: (filter: string) => void;
}

export default function UserTableToolbar({
  filterName,
  onFilterName,
  filterActive,
  onFilterActive,
}: OfferTableToolbarProps) {
  const [filter, setFilter] = useState("");

  return (
    <Toolbar
      sx={{
        height: 96,
        display: "flex",
        justifyContent: "space-between",
        p: (theme) => theme.spacing(0, 1, 0, 3),
      }}
    >
      <Stack direction="row" spacing={2}>
        <OutlinedInput
          value={filter || filterName}
          onChange={(e) => {
            setFilter(e.target.value);
            if (!e.target.value) {
              onFilterName("");
            }
          }}
          placeholder="Search user..."
          startAdornment={
            <InputAdornment position="start">
              <SearchRounded
                sx={{ color: "text.disabled", width: 20, height: 20 }}
              />
            </InputAdornment>
          }
        />
        {/* <FormControl> */}
        {/* <InputLabel id="filter-active">Filter Active</InputLabel> */}
        <Select
          defaultValue={"all"}
          value={filterActive}
          labelId="filter-active"
          // label="Filter Active"
          onChange={(e) => {
            onFilterActive(e.target.value as FilterActiveType);
          }}
          sx={{ minWidth: 130 }}
        >
          <MenuItem value="all">All</MenuItem>
          <MenuItem value="active">Active</MenuItem>
          <MenuItem value="inactive">Inactive</MenuItem>
        </Select>
      </Stack>
      <Tooltip title="Filter list">
        <Button variant="outlined" onClick={() => onFilterName(filter)}>
          <SearchRounded />
          find
        </Button>
      </Tooltip>
    </Toolbar>
  );
}
