import { PhotoAlbumOutlined } from "@mui/icons-material";
import { Box, FormHelperText, SxProps, Typography } from "@mui/material";
import Image from "components/Image";
import UploadSingleFile, {
  UploadFile,
} from "components/upload/UploadSingleFile";
import { baseUrl } from "config";
import { useField } from "formik";
import { isString } from "lodash";
import { ReactNode } from "react";
import { DropzoneOptions, useDropzone } from "react-dropzone";

interface RHFUploadAvatarProps extends DropzoneOptions {
  name: string;
  helperText?: ReactNode;
  initialUrl?: string;
}
export function FUploadAvatar({
  name,
  helperText,
  initialUrl,
  ...other
}: RHFUploadAvatarProps) {
  const [field, meta, helpers] = useField(name);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    fileRejections,
  } = useDropzone({
    multiple: false,
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      helpers.setValue(file);
    },
    onError: (error) => {
      helpers.setError(error.message);
    },
    ...other,
  });

  const imgURL = field.value
    ? isString(field.value)
      ? `${baseUrl + field.value}`
      : URL.createObjectURL(field.value)
    : initialUrl;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 1,
      }}
    >
      <Box
        sx={{
          width: 140,
          height: 140,
          borderRadius: "50%",
          border: `1px dashed `,
        }}
      >
        <Box
          {...getRootProps()}
          sx={{
            overflow: "hidden",
            borderRadius: "50%",
            position: "relative",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
            margin: "auto",
            "& > *": { width: "100%", height: "100%" },
            "&:hover": {
              cursor: "pointer",
              "& .placeholder": {
                zIndex: 9,
              },
            },
            ...(isDragActive && { opacity: 0.72 }),
          }}
        >
          <input {...getInputProps()} />
          {imgURL && <Image alt="avatar" src={imgURL} sx={{ zIndex: 8 }} />}
          <Box
            className="placeholder"
            sx={{
              display: "flex",
              position: "absolute",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
              color: "text.secondary",
              backgroundColor: "background.paper",
              ...(field.value && {
                opacity: 0,
                color: "common.white",
                bgcolor: "grey.900",
                "&:hover": { opacity: 0.72 },
              }),
              ...((isDragReject || meta.error) && {
                bgcolor: "error.lighter",
              }),
            }}
          >
            <PhotoAlbumOutlined />
            <Typography variant="caption">
              {imgURL ? "Update photo" : "Upload photo"}
            </Typography>
          </Box>
        </Box>
      </Box>
      {!!meta.error && (
        <FormHelperText error sx={{ px: 2, textAlign: "center" }}>
          {meta.error}
        </FormHelperText>
      )}
      {helperText}
    </Box>
  );
}

interface UploadSingleFileProps extends DropzoneOptions {
  error?: boolean;
  helperText?: ReactNode;
  name: string;
}

export function FUploadSingleFile({
  name,
  helperText,
  ...other
}: UploadSingleFileProps) {
  const [field, meta, helpers] = useField(name);

  return (
    <UploadSingleFile
      accept={other.accept}
      file={field.value}
      onDrop={(acceptedFiles) => {
        const file: UploadFile = acceptedFiles[0];
        file.preview = URL.createObjectURL(file);
        helpers.setValue(file);
      }}
      onError={(error) => {
        helpers.setError(error.message);
      }}
      helperText={
        <>
          {!!meta.error && (
            <FormHelperText error sx={{ px: 2, textAlign: "center" }}>
              {meta.error}
            </FormHelperText>
          )}
          {helperText}
        </>
      }
      {...other}
    />
  );
}
