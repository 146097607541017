import { ICategory } from "helpers/interfaces";
import useSWR from "swr";
import { API } from "utils/axios/paths";

const useCategory = () => {
  const response = useSWR<ICategory[]>(API.CATEGORIES, {
    refreshInterval: 0,
    errorRetryCount: 2,
  });

  return response;
};

export default useCategory;
