import { useState } from "react";
import { Link, Link as RouterLink, useNavigate } from "react-router-dom";
// @mui
import { alpha } from "@mui/material/styles";
import {
  Box,
  Divider,
  Typography,
  Stack,
  MenuItem,
  Theme,
  Button,
  IconButton,
  Popover,
} from "@mui/material";
import Avatar from "components/Avatar";
import useAuth from "hooks/useAuth";
import { PATHS } from "routes/paths";
import useProfilePhoto from "hooks/swr/useProfilePhoto";
import { useTranslation } from "react-i18next";

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const { t } = useTranslation();
  const MENU_OPTIONS = [
    {
      label: t("navbar.home"),
      linkTo: "/",
    },
    {
      label: t("settings.profile"),
      linkTo: PATHS.PROFILE.ROOT,
    },
  ];
  const navigate = useNavigate();

  const { AuthState, logout } = useAuth();
  const { data: photoURL } = useProfilePhoto();
  const [open, setOpen] = useState<null | HTMLElement>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate(PATHS.AUTH.LOGIN, { replace: true });
    } catch (error) {
      console.error(error);
    }
  };

  if (!AuthState.isAuthenticated) {
    return (
      <Stack direction="row" spacing={1}>
        <Button
          to={PATHS.AUTH.REGISTER}
          component={RouterLink}
          variant="outlined"
        >
          {t("signUp.title")}
        </Button>
        <Button
          to={PATHS.AUTH.LOGIN}
          component={RouterLink}
          variant="contained"
        >
          {t("signIn.title")}
        </Button>
      </Stack>
    );
  }

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme: Theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src={photoURL} color="primary">
          {AuthState.user?.firstname.charAt(0)}
        </Avatar>
      </IconButton>

      <Popover
        open={!!open}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Box sx={{ my: 1.5, px: 2 }}>
          <Typography variant="subtitle2" noWrap>
            {AuthState.user?.firstname + " " + AuthState.user?.lastname}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {AuthState.user?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        {MENU_OPTIONS.map((option) => (
          <Box
            component={Link}
            key={option.label}
            to={option.linkTo}
            sx={{ textDecoration: "none" }}
          >
            <MenuItem
              onClick={handleClose}
              sx={{ color: "grey.700", fontWeight: 400 }}
            >
              {option.label}
            </MenuItem>
          </Box>
        ))}

        <Divider sx={{ borderStyle: "dashed", m: 0 }} />

        <MenuItem
          disableRipple
          disableTouchRipple
          onClick={handleLogout}
          sx={{ typography: "body2", color: "error.main", py: 1.5 }}
        >
          Logout
        </MenuItem>
      </Popover>
    </>
  );
}
