import { ITranslation } from "./i18n";

const translationFr: ITranslation = {
  welcome: "Bienvenue",
  logout: "Déconnexion",
  navbar: {
    home: "Accueil",
    offers: "Offres",
    dashboard: "Tableau de bord",
    about: "À propos",
    contact: "Contact",
  },
  home: {
    hero: {
      title: "Commencez votre voyage avec",
      company: "Main-d'œuvre qualifiée",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos, perferendis!",
      userSpace: "Espace Utilisateur",
      browseOffers: "Parcourir nos offres",
    },
    partners: {
      title: "Faites confiance à nos meilleurs partenaires",
    },
    reviews: {
      title: "Avis",
      description:
        "Découvrez ce que nos clients aiment dans nos produits. Découvrez comment nous excellons en efficacité, durabilité et satisfaction. Rejoignez-nous pour la qualité, l'innovation et un support fiable.",
    },
  },
  signIn: {
    title: "Connexion",
    dontHaveAcount: "Vous n'avez pas de compte ?",
    forgotPass: "Mot de passe oublié ?",
    signinto: "Connectez-vous à ...",
    enterDetails: "Entrez vos informations ci-dessous",
  },
  signUp: {
    title: "S'inscrire",
    firstName: "Prénom",
    lastName: "Nom",
    userName: "Nom d'utilisateur",
    email: "Adresse email",
    password: "Mot de passe",
    confirmPassword: "Confirmer le mot de passe",
    cerateAccount: "Créer un compte",
    getStartedFree: "Commencez gratuitement.",
    AlreadyHave: "Vous avez déjà un compte ?",
    error: {
      alreadyExist: "L'email existe déjà",
      failed: "Échec de l'inscription",
    },
  },
  header: {
    home: "Accueil",
    feed: "Flux",
    authors: "Auteurs",
    explore: "Explorer",
    blog: "Blog",
    contact: "Contact",
  },
  settings: {
    profile: "Profil",
    notifications: "Notifications",
    email: "Email",
    privacySecurity: "Confidentialité et Sécurité",
  },
  messages: {
    somthingWentWrong: "Quelque chose s'est mal passé",
    emailOrPasswordIncorrect: "Email ou mot de passe incorrect",
    success: "Succès",
    noResult: "Aucun résultat",
  },

  footer: {
    description: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    company: {
      title: "Société",
      aboutUs: "À propos de nous",
      contact: "Contact",
    },
    legal: {
      title: "Légal",
      terms: "Conditions",
      privacy: "Confidentialité",
    },
  },
  profile: {
    title: "Profil",
    editProfileMsg: "Les informations peuvent être modifiées",
    general: "Général",
    resumes: "CV",
    pageTitle: "Utilisateur: Paramètres du compte",
  },
  offers: {
    title: "Offres",
    helmet: "Parcourir les offres",
    search: "Rechercher",
    rowsPerPage: "Lignes par page",
    application: "Candidature",
    category: "Catégorie",
    requirements: "Exigences",
    description: "Description",
    reqMsg: "Lire attentivement les exigences avant de postuler",
    alreadySubmited: "déjà soumis",
    gotoApplication: "aller à la candidature",
    submitApplication: "Soumettre la candidature",
    loginToSubmit: "Connectez-vous pour soumettre",
  },
  form: {
    errors: {
      errorUploadingResume:
        "Erreur lors du téléchargement du CV, veuillez réessayer !",
    },
    btn: {
      saveChanges: "Enregistrer les modifications",
      savePhoto: "Enregistrer la photo",
      saved: "Enregistré",
      cancel: "Annuler",
      dropOrSelect: "Déposer ou sélectionner un fichier",
      dropHere: "Déposez les fichiers ici ou cliquez",
      browse: "Parcourir",
      throughtComputer: "via votre ordinateur",
    },
    validation: {
      firstNameRequired: "Le prénom est requis",
      lastNameRequired: "Le nom de famille est requis",
      invalidEmail: "Doit être un email valide",
      emailRequired: "L'email est requis",

      oldPasswordRequired: "Ancien mot de passe requis",
      newPasswordRequired: "Nouveau mot de passe requis",
      newPasswordMin: "Le mot de passe doit contenir au moins 6 caractères",
      passwordsMustMatch: "Les mots de passe doivent correspondre",

      firstNameMin: "Trop court !",
      firstNameMax: "Trop long !",
      lastNameMin: "Trop court !",
      lastNameMax: "Trop long !",
      phoneInvalid: "Le numéro de téléphone n'est pas valide",
      phoneRequired: "Le numéro de téléphone est requis",
      passwordRequired: "Le mot de passe est requis",
      passwordMin: "Le mot de passe doit contenir au moins 8 caractères",
      passwordLowercase:
        "Le mot de passe doit contenir au moins une lettre minuscule",
      passwordNumber: "Le mot de passe doit contenir au moins un chiffre",
    },
    labels: {
      firstname: "Prénom",
      lastname: "Nom de famille",
      email: "Email",
      phone: "Téléphone",
      password: "Mot de passe",
      oldPassword: "Ancien mot de passe",
      newPassword: "Nouveau mot de passe",
      confirmNewPassword: "Confirmer le nouveau mot de passe",
    },
    allowed: "Autorisé",
    maxSize: "Taille maximale de ",
  },
};

export default translationFr;
