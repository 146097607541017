import { ITranslation } from "./i18n";

const translationEn: ITranslation = {
  welcome: "Welcome",
  logout: "logout",
  navbar: {
    home: "Home",
    offers: "Offers",
    dashboard: "Dashboard",
    about: "About",
    contact: "Contact",
  },
  home: {
    hero: {
      title: "Start your journey with",
      company: "Skilled Workforce",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos, perferendis!",
      userSpace: "User Space",
      browseOffers: "Browse Our Offers",
    },
    partners: {
      title: "Trusted by our best partners",
    },
    reviews: {
      title: "Reviews",
      description:
        "See what our customers love about our products. Discover how we excel in efficiency, durability, and satisfaction. Join us for quality, innovation, and reliable support.",
    },
  },
  signIn: {
    title: "login",
    dontHaveAcount: "Don't have an account?",
    forgotPass: "Forgot password?",
    signinto: "Sign in to ...",
    enterDetails: "Enter your details below",
  },
  signUp: {
    title: "sign up",
    firstName: "first name",
    lastName: "last name",
    userName: "user name",
    email: "email address",
    password: "password",
    confirmPassword: "confirm password",
    cerateAccount: "create account",
    getStartedFree: "Get started absolutely free.",
    AlreadyHave: "Already have an account?",
    error: {
      alreadyExist: "email already exist",
      failed: "register failed",
    },
  },
  header: {
    home: "Home",
    feed: "Feed",
    authors: "Authors",
    explore: "Explore",
    blog: "Blog",
    contact: "Contact",
  },
  settings: {
    profile: "Profile",
    notifications: "Notifications",
    email: "email",
    privacySecurity: "Privacy and Security",
  },
  messages: {
    somthingWentWrong: "Something went wrong",
    emailOrPasswordIncorrect: "Email or password Incorrect",
    success: "Succeed",
    noResult: "No result",
  },

  footer: {
    description: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    company: {
      title: "Company",
      aboutUs: "About us",
      contact: "Contact",
    },
    legal: {
      title: "Legal",
      terms: "Terms",
      privacy: "Privacy",
    },
  },
  profile: {
    title: "Profile",
    editProfileMsg: "The information can be edited",
    general: "General",
    resumes: "Resumes",
    pageTitle: "User: Account Settings",
  },
  offers: {
    title: "Offers",
    helmet: "Browse Offers",
    search: "Search",
    rowsPerPage: "Rows per page",
    application: "Application",
    category: "Category",
    requirements: "Requirements",
    description: "Description",
    reqMsg: "read the carfully the requirements before applying",
    alreadySubmited: "already submited",
    gotoApplication: "go to application",
    submitApplication: "Submit application",
    loginToSubmit: "login to Submit",
  },
  form: {
    errors: {
      errorUploadingResume: "Error uploading resume, please try again!",
    },
    btn: {
      saveChanges: "Save Changes",
      savePhoto: "Save Photo",
      saved: "Saved",
      cancel: "Cancel",
      dropOrSelect: "Drop or Select file",
      dropHere: "Drop files here or click",
      browse: "Browse",
      throughtComputer: "through your computer",
    },
    validation: {
      firstNameRequired: "First name is required",
      lastNameRequired: "Last name is required",
      invalidEmail: "Must be a valid email",
      emailRequired: "Email is required",

      oldPasswordRequired: "Old Password is required",
      newPasswordRequired: "New Password is required",
      newPasswordMin: "Password must be at least 6 characters",
      passwordsMustMatch: "Passwords must match",

      firstNameMin: "Too Short!",
      firstNameMax: "Too Long!",
      lastNameMin: "Too Short!",
      lastNameMax: "Too Long!",
      phoneInvalid: "Phone number is not valid",
      phoneRequired: "Phone number is required",
      passwordRequired: "Password is required",
      passwordMin: "Password must be at least 8 characters",
      passwordLowercase: "Password must contain at least a lowercase letter",
      passwordNumber: "Password must contain at least a number",
    },
    labels: {
      firstname: "First Name",
      lastname: "Last Name",
      email: "Email",
      phone: "Phone",
      password: "Password",
      oldPassword: "Old Password",
      newPassword: "New Password",
      confirmNewPassword: "Confirm New Password",
    },
    allowed: "Allowed",
    maxSize: "Max size of ",
  },
};

export default translationEn;
