import { Link as RouterLink } from "react-router-dom";
// @mui
// import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";
import { SxProps } from "@mui/system";
import Image from "./Image";
// ----------------------------------------------------------------------

interface LogoProps {
  disabledLink?: boolean;
  sx?: SxProps;
  imgSx?: SxProps;
  withTitle?: boolean;
}

export default function Logo({
  disabledLink = false,
  sx,
  withTitle = false,
  imgSx,
}: LogoProps) {
  // const theme = useTheme();
  // const PRIMARY_LIGHT = theme.palette.primary.light;
  // const PRIMARY_MAIN = theme.palette.primary.main;
  // const PRIMARY_DARK = theme.palette.primary.dark;

  return (
    <Box
      sx={{
        fontWeight: 700,
        color: "primary.main",
        textDecoration: "none", // This removes the underline
        display: "flex",
        width: "fit-content",
        flexDirection: "row",
        alignItems: "center",
        cursor: "pointer",
        // width: "auto",
        // p: 1,
        // borderRadius: 1,
        // border: 1,
        // borderColor: "primary.lighter",
        ...sx,
      }}
      {...(!disabledLink && {
        component: RouterLink,
        to: "/",
      })}
    >
      <Image
        removeWrapper
        sx={{ height: 50, objectFit: "cover", ...imgSx }}
        src="./logo.png"
        alt="logo"
      />

      {withTitle && (
        <Box sx={{ ml: 1, display: "inline-block" }}>Skilled Workforce</Box>
      )}
    </Box>
  );
}
