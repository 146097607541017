import useSWR from "swr";
import client from "utils/axios";
import { API } from "utils/axios/paths";

const fetcher = async (url: string) => {
  return client
    .get(url, {})
    .then((res) => res.data)
    .catch((err) => {
      return null;
    });
};

const useResume = () => {
  const response = useSWR<string>(API.USER.RESUME, {
    fetcher,
    refreshInterval: 0,
    errorRetryCount: 0,
  });

  return response;
};

export default useResume;
