import { Box, Divider, Toolbar } from "@mui/material";
import { Outlet } from "react-router-dom";
import NavbarHorizontal from "./navbar/NavbarHorizontal";
import Footer from "./footer";

function SimpleLayout() {
  return (
    <>
      <NavbarHorizontal />
      <Box>
        <Toolbar />
        <Outlet />
      </Box>
      <Divider />
      <Footer />
    </>
  );
}

export default SimpleLayout;
