import { Container } from "@mui/system";
import LoadingScreen from "components/LoadingScreen";
import OfferView from "components/sections/offer-details-view";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import useSWR from "swr";
import { API } from "utils/axios/paths";

export default function Offer() {
  let { OfferId } = useParams();

  const { data, error, isLoading } = useSWR(`${API.OFFERS}/${OfferId}`);
  const { t } = useTranslation();

  if (error) return <div>{t("messages.somthingWentWrong")}</div>;
  if (isLoading) return <LoadingScreen />;

  return (
    <>
      <Helmet>
        <title>{t("offers.helmet")}</title>
      </Helmet>
      <Container>
        <OfferView offer={data} />
      </Container>
    </>
  );
}
