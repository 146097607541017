import { Helmet } from "react-helmet-async";

import DashCategoriesView from "components/sections/dash-categories/dash-categories-view";

// ----------------------------------------------------------------------

export default function ManageCategoriesPage() {
  return (
    <>
      <Helmet>
        <title> Manage Categories </title>
      </Helmet>

      <DashCategoriesView />
    </>
  );
}
