import { Link as RouterLink } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
import { Box, Card, Link, Container, Typography } from "@mui/material";
import useResponsive from "hooks/useResponsive";
import Page from "components/Page";
import Logo from "components/Logo";
import Image from "components/Image";
import { RegisterForm } from "components/auth/register";
import { PATHS } from "routes/paths";
import { useTranslation } from "react-i18next";

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const HeaderStyle = styled("header")(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: "100%",
  display: "flex",
  alignItems: "center",
  position: "absolute",
  padding: theme.spacing(3),
  justifyContent: "space-between",
  [theme.breakpoints.up("md")]: {
    alignItems: "flex-start",
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Register() {
  const smUp = useResponsive("up", "sm");
  const mdUp = useResponsive("up", "md");
  const { t } = useTranslation();

  return (
    <Page title={t("signUp.title")}>
      <RootStyle>
        <HeaderStyle>
          <Logo />
          {smUp && (
            <Typography variant="body2" sx={{ mt: { md: -2 } }}>
              {t("signUp.AlreadyHave")}
              <Link
                variant="subtitle2"
                component={RouterLink}
                to={PATHS.AUTH.LOGIN}
              >
                {t("signIn.title")}
              </Link>
            </Typography>
          )}
        </HeaderStyle>

        {mdUp && (
          <Card
            sx={{
              width: "100%",
              maxWidth: 464,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              margin: (theme) => theme.spacing(2, 0, 2, 2),
            }}
          >
            <Image
              alt="register"
              src="https://images.unsplash.com/photo-1521295121783-8a321d551ad2?q=80&w=3870&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              sx={{
                top: 0,
                width: "100%",
                height: "100%",
                objectFit: "cover",
                position: "absolute",
                zIndex: -1,
              }}
            />
          </Card>
        )}

        <Container>
          <ContentStyle>
            <Box sx={{ mb: 5, display: "flex", alignItems: "center" }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h4" gutterBottom>
                  {t("signUp.getStartedFree")}
                </Typography>
              </Box>
            </Box>

            <RegisterForm />

            {!smUp && (
              <Typography variant="body2" sx={{ mt: 3, textAlign: "center" }}>
                {t("signUp.AlreadyHave")}
                <Link
                  variant="subtitle2"
                  to={PATHS.AUTH.LOGIN}
                  component={RouterLink}
                >
                  {t("signIn.title")}
                </Link>
              </Typography>
            )}
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
