import { IUser } from "helpers/interfaces";
import useSWR from "swr";
import { API } from "utils/axios/paths";

interface UseUsersProps {
  page?: number;
  max?: number;
  filter?: string;
}

const useUsers = ({ page = 0, max = 5, filter }: UseUsersProps) => {
  const response = useSWR<IUser[]>(
    API.MANAGER.USERS + `?offset=${page * max}&max=${max + 1}&filter=${filter}`
  );

  // return response;
  return {
    ...response,
    data: response?.data?.slice(0, max),
    hasNext: response.data?.length === max + 1,
  };
};

export default useUsers;
