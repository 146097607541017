import { IRole, RestrectedTo } from "helpers/interfaces";
import useAuth from "hooks/useAuth";
import React, { PropsWithChildren } from "react";

interface Props extends PropsWithChildren {
  to?: RestrectedTo[];
  fallback?: JSX.Element | string;
}

export const Restricted: React.FunctionComponent<Props> = ({
  to,
  fallback,
  children,
}) => {
  const { AuthState } = useAuth();

  if (!to) {
    return <>{children}</>;
  } else if (
    (to.includes("LOGGED_IN") && AuthState?.isAuthenticated) ||
    to.includes(AuthState.user?.role as IRole) ||
    (to.includes("GUEST") && !AuthState?.isAuthenticated)
  ) {
    return <>{children}</>;
  } else if (!fallback) {
    return <></>;
  }

  return <>{fallback}</>;
};
