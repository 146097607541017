import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { useState } from "react";
import {
  Alert,
  Box,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { LoadingButton } from "@mui/lab";
import useAuth from "hooks/useAuth";
import { useNavigate } from "react-router";
import { strengthColor, strengthIndicator } from "utils/password-strength";
import { useTranslation } from "react-i18next";

export default function RegisterFormProf() {
  const [showPassword, setShowPassword] = useState(false);
  const [level, setLevel] = useState<{ label: string; color: string }>();
  const { t } = useTranslation();
  const changePassword = (value: string) => {
    const temp = strengthIndicator(value);
    setLevel(strengthColor(temp));
  };

  const { register } = useAuth();
  const navigate = useNavigate();

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, t("form.validation.firstNameMin"))
      .max(50, t("form.validation.firstNameMax"))
      .required(t("form.validation.firstNameRequired")),
    lastName: Yup.string()
      .min(2, t("form.validation.lastNameMin"))
      .max(50, t("form.validation.lastNameMax"))
      .required(t("form.validation.lastNameRequired")),
    email: Yup.string()
      .email(t("form.validation.invalidEmail"))
      .required(t("form.validation.emailRequired")),
    phone: Yup.string()
      .matches(
        /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
        t("form.validation.phoneInvalid")
      )
      .required(t("form.validation.phoneRequired")),
    password: Yup.string()
      .required(t("form.validation.passwordRequired"))
      .min(8, t("form.validation.passwordMin"))
      .matches(/[a-z]/, t("form.validation.passwordLowercase"))
      .matches(/[0-9]/, t("form.validation.passwordNumber")),
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      age: "",
      password: "",
      afterSubmit: null,
    },
    validationSchema: RegisterSchema,
    onSubmit: async (values, { setErrors, setSubmitting, setFieldError }) => {
      try {
        setSubmitting(true);
        await register(values);

        navigate("/", { replace: true });
      } catch (error) {
        setErrors({ afterSubmit: "something went wrong" });
      } finally {
        setSubmitting(false);
      }
    },
  });
  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          {!!errors.afterSubmit && (
            <Alert severity="error">{errors.afterSubmit}</Alert>
          )}
          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            <TextField
              fullWidth
              label={t("form.labels.firstname")}
              {...getFieldProps("firstName")}
              error={Boolean(touched.firstName && errors.firstName)}
              helperText={touched.firstName && errors.firstName}
            />
            <TextField
              fullWidth
              label={t("form.labels.lastname")}
              {...getFieldProps("lastName")}
              error={Boolean(touched.lastName && errors.lastName)}
              helperText={touched.lastName && errors.lastName}
            />
          </Stack>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label={t("form.labels.email")}
            {...getFieldProps("email")}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
          {/* <TextField
            fullWidth
            type="number"
            label="Age"
            {...getFieldProps("age")}
            error={Boolean(touched.age && errors.age)}
            helperText={touched.age && errors.age}
          /> */}

          <TextField
            fullWidth
            type="text"
            label={t("form.labels.phone")}
            {...getFieldProps("phone")}
            error={Boolean(touched.phone && errors.phone)}
            helperText={touched.phone && errors.phone}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? "text" : "password"}
            label={t("form.labels.password")}
            {...getFieldProps("password")}
            onChange={(e) => {
              getFieldProps("password").onChange(e);
              changePassword(e.target.value);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    onClick={() => setShowPassword((prev) => !prev)}
                  >
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />

          <FormControl fullWidth sx={{ mt: 2 }}>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <Box
                  sx={{
                    bgcolor: level?.color,
                    width: 85,
                    height: 8,
                    borderRadius: "7px",
                  }}
                />
              </Grid>
              <Grid item>
                <Typography variant="subtitle1" fontSize="0.75rem">
                  {level?.label}
                </Typography>
              </Grid>
            </Grid>
          </FormControl>

          <LoadingButton
            fullWidth
            type="submit"
            size="large"
            variant="contained"
            loading={isSubmitting}
          >
            {t("signUp.title")}
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
export {};
