import { useState } from "react";
import client from "utils/axios";
import { useSnackbar } from "notistack";
import i18n from "locales/i18n";
import {
  AxiosResponse,
  CancelTokenSource,
  AxiosError,
  AxiosRequestConfig,
} from "axios";
import errorHandler from "helpers/errorHandler";

const { t } = i18n;

export const GET = "get";
export const POST = "post";
export const PATCH = "patch";
export const DELETE = "delete";
export const PUT = "put";
export const ARRAY_METHODE = [POST, PATCH, DELETE, PUT];

interface CallArgs {
  source?: CancelTokenSource;
  params?: object;
  body?: object;
  moreOptions?: AxiosRequestConfig;
}

type Method = "get" | "post" | "patch" | "delete" | "put";

const useFetch = (method: Method, path: string, { showErrors = true } = {}) => {
  const [response, setResponse] = useState<AxiosResponse>();
  const [error, setError] = useState<Error>();
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const call = async ({ source, params, body, moreOptions }: CallArgs) => {
    try {
      setIsLoading(true);
      let response = await _call(
        path,
        params,
        body,
        method,
        source,
        moreOptions
      );

      setResponse(response);

      if (ARRAY_METHODE.includes(method))
        enqueueSnackbar(t("messages.success") as string, {
          variant: "success",
        });
      if (response?.status === 204 && showErrors) {
        enqueueSnackbar(t("messages.noResult") as string, {
          variant: "success",
        });
      }
      return response;
    } catch (e) {
      if (e instanceof AxiosError) {
        setError(e);
        showErrors && handleError(path, e);
        throw e;
      } else {
        const defaultError = new Error(t("messages.somthingWentWrong") || "");
        setError(defaultError);
        showErrors &&
          enqueueSnackbar(defaultError.message, { variant: "error" });
        throw defaultError;
      }
    } finally {
      setIsLoading(false);
    }
  };

  return {
    data: response?.data,
    response,
    error,
    call,
    isLoading,
  };

  async function _call(
    path: string,
    params: object | undefined,
    body: object | undefined,
    method: string,
    source: CancelTokenSource | undefined,
    options: AxiosRequestConfig | undefined
  ) {
    switch (true) {
      case [POST, PUT, PATCH].includes(method):
        return client.request({
          method,
          url: path,
          cancelToken: source?.token,
          data: body,
          params,
          ...options,
        });
      case [GET, DELETE].includes(method):
        return client.request({
          method,
          url: path,
          cancelToken: source?.token,
          params,
          ...options,
        });
      default:
        throw new Error(`Method ${method} is not supported`);
    }
  }

  function handleError(path: string, e: AxiosError) {
    const code = e.response?.status || 0;
    const error =
      t(errorHandler[path][code]) || t("messages.somthingWentWrong");
    enqueueSnackbar(error, { variant: "error" });
  }
};

export default useFetch;
