import { Helmet } from "react-helmet-async";

import { UserView } from "components/sections/dash-users/view";

// ----------------------------------------------------------------------

export default function ManageUsersPage() {
  return (
    <>
      <Helmet>
        <title> Manage User </title>
      </Helmet>

      <UserView />
    </>
  );
}
