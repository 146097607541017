import { motion as m } from "framer-motion";
import Logo from "./Logo";
import { Box, LinearProgress } from "@mui/material";

export default function LoadingScreen({ isProgress = false }) {
  if (isProgress) {
    return <LinearProgress />;
  }

  return (
    <Box
      component={"div"}
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        bgcolor: "background.paper",
        zIndex: 100000,
      }}
    >
      <m.div
        animate={{ scale: [1.1, 1, 1, 1, 1.1] }}
        transition={{
          duration: 2,
          ease: "easeInOut",
          repeatDelay: 1,
          repeat: Infinity,
        }}
      >
        <Logo />
      </m.div>
      <Box
        component={m.div}
        animate={{
          scale: [1.2, 1, 1, 1.2, 1.2],
          opacity: [0.25, 1, 1, 1, 0.25],
        }}
        transition={{ ease: "linear", duration: 3.2, repeat: Infinity }}
        sx={{
          width: 60,
          height: 60,
          borderRadius: "50%",
          border: "4px solid",
          borderColor: "primary.main",
          position: "absolute",
        }}
      />
      <Box
        component={m.div}
        animate={{
          scale: [1, 1.2, 1.2, 1, 1],
          opacity: [1, 0.25, 0.25, 0.25, 1],
        }}
        transition={{
          ease: "linear",
          duration: 3.2,
          repeat: Infinity,
        }}
        sx={{
          width: 60,
          height: 60,
          borderRadius: "50%",
          border: "4px solid",
          borderColor: "primary.main",
          position: "absolute",
        }}
      />
    </Box>
  );
}
