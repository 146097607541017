import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import { API } from "utils/axios/paths";
import {
  AddRounded,
  CloseRounded,
  DeleteRounded,
  EditRounded,
  PlusOneRounded,
} from "@mui/icons-material";
import useSWR from "swr";
import MyTable from "components/tables";
import {
  Alert,
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  MenuItem,
  Modal,
  OutlinedInput,
} from "@mui/material";
import { useState } from "react";
import useFetch from "hooks/useFetch";
import { LoadingButton } from "@mui/lab";
import useCategory from "hooks/swr/useCategories";

export default function DashCategoriesView() {
  const { data: categories, mutate } = useCategory();

  // const categories: { id: string; category: string }[] = data;

  const handleEdit = (category: any) => {
    console.log(category);
    mutate();
  };

  const handleDelete = (category: any) => {
    console.log(category);
    mutate();
  };

  return (
    <Container>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography variant="h4">Categories</Typography>

        <AddCategory onAdd={() => mutate()} />
      </Stack>

      <MyTable
        columns={[
          {
            id: "category",
            label: "Category",
            key: "category",
            order: true,
          },
          {
            id: "actions",
            label: "Actions",
            render: ({ category, id }) => (
              <Stack direction={"row"} spacing={2}>
                <MenuItem onClick={() => handleEdit(category)}>
                  <EditRounded sx={{ mr: 1, height: 18 }} />
                  Edit
                </MenuItem>

                <MenuItem
                  onClick={() => handleDelete(category)}
                  sx={{ color: "error.main" }}
                >
                  <DeleteRounded sx={{ mr: 1, height: 18 }} />
                  Delete
                </MenuItem>
              </Stack>
            ),
          },
        ]}
        dataSource={{
          data: categories || [],
          key: "id",
        }}
        // pagination={{
        //   count: categories?.length || 0,
        //   onPageChange: (event, newPage) => {
        //     console.log(newPage);
        //   },
        // }}
        sort={{
          onSort: (id, order) => {
            console.log(id, order);
          },
        }}
      />
    </Container>
  );
}

const AddCategory = ({ onAdd }: { onAdd?: (category: string) => void }) => {
  const [open, setOpen] = useState(false);
  const [category, setCategory] = useState("");
  const { call, isLoading, error } = useFetch("post", API.MANAGER.CATEGORY);

  const handleClose = () => setOpen(false);

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        variant="contained"
        color="inherit"
        startIcon={<AddRounded />}
      >
        New Category
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
          }}
        >
          <CardHeader
            title="Add Category"
            action={
              <Button onClick={handleClose}>
                <CloseRounded />
              </Button>
            }
          />
          <CardContent>
            {error && <Alert severity="error">somthing went wrong</Alert>}
            <OutlinedInput
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              placeholder="Category"
            />
          </CardContent>
          <CardActions>
            <LoadingButton
              variant="contained"
              disabled={isLoading}
              loading={isLoading}
              onClick={async () => {
                try {
                  await call({ body: { category } });
                  onAdd && onAdd(category);
                  setCategory("");
                  handleClose();
                } catch (error) {
                  console.error(error);
                }
              }}
            >
              Add
            </LoadingButton>
          </CardActions>
        </Card>
      </Modal>
    </>
  );
};
