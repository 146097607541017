import { memo, forwardRef } from "react";
import Box, { BoxProps } from "@mui/material/Box";
import { StyledScrollbar, StyledRootScrollbar } from "./styles";

// ----------------------------------------------------------------------
interface ScrollbarProps {
  children?: React.ReactNode;
  sx?: BoxProps["sx"];
}

const Scrollbar = forwardRef(
  ({ children, sx, ...other }: ScrollbarProps, ref) => {
    const userAgent =
      typeof navigator === "undefined" ? "SSR" : navigator.userAgent;

    const mobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        userAgent
      );

    if (mobile) {
      return (
        <Box ref={ref} sx={{ overflow: "auto", ...sx }} {...other}>
          {children}
        </Box>
      );
    }

    return (
      <StyledRootScrollbar>
        <StyledScrollbar
          scrollableNodeProps={{
            ref,
          }}
          clickOnTrack={false}
          sx={sx}
          color="primary" // Add a valid color value here, such as "primary" or "#000000"
          {...other}
        >
          {children}
        </StyledScrollbar>
      </StyledRootScrollbar>
    );
  }
);

export default memo(Scrollbar);
