import { forwardRef } from "react";
import { AnyObject, InternalTableProps, InternalTable } from "./InternalTable";

export type RefTable = <RecordType extends AnyObject = AnyObject>(
  props: React.PropsWithChildren<InternalTableProps<RecordType>>
) => React.ReactElement;

const Table = <RecordType extends AnyObject = AnyObject>(
  props: InternalTableProps<RecordType>,
  ref: React.Ref<HTMLDivElement>
) => {
  return <InternalTable<RecordType> {...props} />;
};

const MyTable = forwardRef(Table) as unknown as RefTable;

export default MyTable;
