import { alpha, styled } from "@mui/material/styles";
import {
  Box,
  Avatar,
  SpeedDial,
  Typography,
  useMediaQuery,
  Theme,
  Card,
  Container,
  CardContent,
  Divider,
  Rating,
  Stack,
  Button,
} from "@mui/material";

import { useLocation, useNavigate } from "react-router-dom";
import { IOffer } from "helpers/interfaces";

import Image from "components/Image";
import { ShareRounded } from "@mui/icons-material";
import { PATHS } from "routes/paths";
import useAuth from "hooks/useAuth";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";

// ----------------------------------------------------------------------

const OverlayStyle = styled("div")(({ theme }) => ({
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: 9,
  position: "absolute",
  borderRadius: " 20px 20px 0 0",
  // [theme.breakpoints.down('sm')]: {
  //   position: 'static',
  // },
  // backgroundColor: alpha(theme.palette.grey[900], 0.78),
  backgroundColor: "#020550",
  opacity: 0.85,
  backdropFilter: "blur(10px)",
}));

const TitleStyle = styled("div")(({ theme }) => ({
  top: 0,
  zIndex: 10,
  width: "100%",
  position: "absolute",
  paddingLeft: 1,
  padding: theme.spacing(3),
  color: theme.palette.common.white,
  [theme.breakpoints.up("lg")]: {
    padding: theme.spacing(10),
    paddingLeft: 1,
  },
}));

const FooterStyle = styled("div")(({ theme }) => ({
  bottom: 0,
  zIndex: 10,
  width: "100%",
  display: "flex",
  position: "absolute",
  alignItems: "flex-end",
  paddingLeft: 1,
  paddingRight: theme.spacing(2),
  paddingBottom: theme.spacing(3),
  justifyContent: "start",
  [theme.breakpoints.up("sm")]: {
    alignItems: "center",
    paddingRight: theme.spacing(3),
    paddingLeft: 1,
  },
  [theme.breakpoints.up("lg")]: {
    padding: theme.spacing(10),
    paddingLeft: 1,
  },
}));

interface Props {
  offer: IOffer;
}

function OfferDetailsHero({ offer }: Props) {
  const { description, createdAt, name, requirements, category, imageUrl } =
    offer;
  const heroHeight = { xs: 400, md: 400, lg: 500 };
  const coverUrl = imageUrl ? imageUrl : "/static/course_placeholder-01.png";

  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));

  return (
    <>
      <Box
        sx={{
          position: "relative",
          height: heroHeight,
        }}
      >
        <Container sx={{ position: "relative", height: "100%" }}>
          <TitleStyle>
            <Typography
              variant="h2"
              sx={{ lineHeight: 1.2, mb: 2 }}
              // fontFamily={"Poppins"}
              color={"secondary.light"}
            >
              {name}
            </Typography>
            <Typography
              variant="h6"
              sx={{
                color: "grey.400",
                fontWeight: 500,
                width: { xs: "100%", md: "50%" },
                display: "inline-block",
              }}
            >
              {description.length > 100
                ? description.slice(0, 100) + "..."
                : description}
            </Typography>
          </TitleStyle>

          <FooterStyle>
            <Box sx={{ display: "flex", alignItems: "center", mr: 10 }}>
              <Avatar
                alt={name}
                src={imageUrl || "/static/img-placeholder.png"}
                sx={{ width: 48, height: 48 }}
              />
              <Box sx={{ ml: 2 }}>
                <Typography
                  variant="subtitle1"
                  sx={{ color: "common.white", textTransform: "capitalize" }}
                >
                  {category}
                </Typography>
                <Typography variant="body2" sx={{ color: "grey.500" }}>
                  {format(createdAt || new Date(), "dd/MM/yyyy")}
                </Typography>
              </Box>
            </Box>

            {/* <SpeedDial
              direction={isDesktop ? "left" : "up"}
              ariaLabel="Share post"
              icon={<ShareRounded sx={{ width: 32, height: 32 }} />}
              sx={{ "& .MuiSpeedDial-fab": { width: 48, height: 48 } }}
            > */}
            {/* {SOCIALS.map((action) => (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                tooltipPlacement="top"
                FabProps={{ color: 'default' }}
              />
            ))} */}
            {/* </SpeedDial> */}
          </FooterStyle>
          {isDesktop && <SubmitCard offer={offer} />}

          <Box
            sx={{
              position: "absolute",
              top: 0,
              right: { xs: 0, sm: "20%" },
              bottom: 0,
            }}
          >
            <Image
              src={coverUrl}
              sx={{
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover", // added this line
                height: "100%", // and this line
                width: "100%", // and this line
              }}
            />
          </Box>
        </Container>
        <OverlayStyle />
      </Box>
      {!isDesktop && <SubmitCard offer={offer} />}
    </>
  );
}

interface SubmitCardProps {
  offer: IOffer;
  submited?: boolean;
}
function SubmitCard({ offer, submited = false }: SubmitCardProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const { AuthState } = useAuth();
  const { t } = useTranslation();
  return (
    <Card
      sx={{
        position: { md: "absolute" },
        bottom: { md: -60 },
        right: { md: 30 },
        zIndex: 30,
        m: 2,
        border: " 1px dashed",
        borderColor: "primary.light",
        boxShadow: (theme) => theme.shadows[10],
      }}
      variant="outlined"
    >
      <CardContent>
        <Typography variant="subtitle1">{t("offers.application")}</Typography>
        <Typography variant="caption">
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eos, dolore.
        </Typography>

        <Divider sx={{ my: 2 }} />

        <Stack
          display={"flex"}
          flexWrap={"wrap"}
          direction="row"
          spacing={1.2}
          alignItems="center"
          sx={{ my: 2, maxWidth: 300 }}
        >
          <Typography variant="subtitle1">
            {t("offers.category")}
            {":"}
          </Typography>

          <Button
            variant="outlined"
            size="small"
            onClick={() => navigate(PATHS.OFFERS.ROOT)}
          >
            {offer.category}
          </Button>
        </Stack>

        <Typography variant="subtitle1">{t("offers.requirements")}</Typography>
        <Typography variant="caption">{t("offers.reqMsg")}</Typography>

        <Divider sx={{ my: 2 }} />

        {submited ? (
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography variant="caption">
              {t("offers.alreadySubmited")}
            </Typography>
            <Button
              variant="contained"
              size="large"
              onClick={() => navigate("/")}
            >
              {t("offers.gotoApplication")}
            </Button>
          </Stack>
        ) : AuthState.isAuthenticated ? (
          <Button
            variant="contained"
            size="large"
            onClick={() => navigate(PATHS.OFFERS.SUBMIT + "/" + offer.id)}
          >
            {t("offers.submitApplication")}
          </Button>
        ) : (
          <Button
            variant="contained"
            size="large"
            onClick={() =>
              navigate(PATHS.AUTH.LOGIN, { state: { from: location } })
            }
          >
            {t("offers.loginToSubmit")}
          </Button>
        )}
      </CardContent>
    </Card>
  );
}

export default OfferDetailsHero;
