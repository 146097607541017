import { useState } from "react";

import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import {
  Box,
  IconButton,
  Pagination,
  TablePagination,
  useTheme,
} from "@mui/material";

import Scrollbar from "components/scrollbar";

import TableNoData from "components/tables/table-no-data";
import TableEmptyRows from "components/tables/table-empty-rows";

import MyTableHead from "components/tables/my-table-head";

import { emptyRows } from "components/tables/utils";
import { Checkbox, TableCell, TableRow } from "@mui/material";
import { TablePaginationActionsProps } from "@mui/material/TablePagination/TablePaginationActions";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";

type OrderType = "asc" | "desc" | undefined;

export type AnyObject = Record<PropertyKey, any>;

interface IDataSource<RecordType = any> {
  data: RecordType[];
  nextPageData?: RecordType[];
  key: keyof RecordType;
}

export interface MyTableColumnProps<T = any> {
  id: string;
  key?: keyof T;
  label?: string;
  render?: (value: T) => JSX.Element;
  order?: boolean;
  align?: "center" | "left" | "right";
  width?: string;
  minWidth?: string;
}

export interface InternalTableProps<RecordType = any> {
  dataSource: IDataSource<RecordType>;
  columns: MyTableColumnProps<RecordType>[];
  pagination?: {
    onPageChange: (event: any, newPage: number, rowsPerPage: number) => void;
    hasNextPage: boolean;
    rowsPerPage?: number;
    rowsPerPageOptions?: number[];
  };
  loading?: boolean;
  TableRowSelection?: {
    onSelect?: (
      record: string,
      selected: boolean,
      selectedRows: string[]
    ) => void;
    onChange?: (selectedRowKeys: string[]) => void;
    onSelectAll?: (selected: boolean, selectedRows: string[]) => void;
    renderCell?: (value: RecordType) => JSX.Element;
  };
  sort?: {
    onSort?: (id: string, order: OrderType) => void;
  };
  toolbar?: JSX.Element;
}

export const InternalTable = <RecordType extends AnyObject = AnyObject>(
  props: InternalTableProps<RecordType>
) => {
  const {
    dataSource,
    columns,
    sort,
    TableRowSelection,
    loading,
    pagination,
    toolbar,
  } = props;

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState<"asc" | "desc" | undefined>("asc");

  const [selected, setSelected] = useState<string[]>([]);

  const [orderBy, setOrderBy] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(pagination?.rowsPerPage || 5);

  const handleSort = (event: any, columnId: string) => {
    if (columnId !== "") {
      if (orderBy === columnId) {
        setOrder(
          order === "asc" ? "desc" : order == "desc" ? undefined : "asc"
        );
      } else {
        setOrderBy(columnId);
        setOrder("asc");
      }

      if (sort?.onSort) {
        sort.onSort(columnId, order);
      }
    }
  };

  const handleSelectAllClick = (event: any) => {
    if (event.target.checked) {
      const newSelecteds = dataSource.data.map(
        (n) => n[dataSource.key] as string
      );
      TableRowSelection?.onSelectAll?.(true, newSelecteds);
      setSelected(newSelecteds);
    } else {
      TableRowSelection?.onSelectAll?.(false, []);
      setSelected([]);
    }
  };

  const handleSelectClick = (event: any, key: string) => {
    const selectedIndex = selected.indexOf(key);
    let newSelected: string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, key);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
    TableRowSelection?.onChange?.(newSelected);
    TableRowSelection?.onSelect?.(key, selectedIndex === -1, newSelected);
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    pagination?.onPageChange(event, newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
    pagination?.onPageChange(event, 0, parseInt(event.target.value, 10));
  };

  const notFound = !dataSource.data || !dataSource.data?.length;

  return (
    <Card>
      {toolbar && <Stack>{toolbar}</Stack>}
      <Scrollbar>
        <TableContainer sx={{ overflow: "unset" }}>
          <Table sx={{ minWidth: 800 }}>
            <MyTableHead
              order={order}
              orderBy={orderBy}
              rowCount={dataSource?.data.length}
              numSelected={TableRowSelection ? selected.length : undefined}
              onRequestSort={handleSort}
              onSelectAllClick={handleSelectAllClick}
              headLabel={columns}
            />
            <TableBody>
              {notFound ? (
                <TableNoData query={""} />
              ) : (
                dataSource.data.map((row) => {
                  const rowKey = row[dataSource.key] as string;
                  const rowSelected = selected.indexOf(rowKey) !== -1;
                  return (
                    <TableRow
                      key={rowKey as string}
                      hover
                      tabIndex={-1}
                      role="checkbox"
                      selected={rowSelected}
                    >
                      {TableRowSelection && (
                        <TableCell padding="checkbox">
                          <Checkbox
                            disableRipple
                            checked={rowSelected}
                            onChange={(e) => handleSelectClick(e, rowKey)}
                          />
                        </TableCell>
                      )}
                      {columns.map((column) => {
                        if (column) {
                          return (
                            <TableCell
                              key={`${rowKey}-${column.id}`}
                              align={column.align || "left"}
                              sx={{
                                width: column.width,
                                minWidth: column.minWidth,
                              }}
                            >
                              {column?.render ? (
                                <>{column.render(row)}</>
                              ) : (
                                <>{column?.key ? row[column.key] : "-"}</>
                              )}
                            </TableCell>
                          );
                        }
                      })}
                    </TableRow>
                  );
                })
              )}

              <TableEmptyRows
                height={77}
                emptyRows={emptyRows(
                  rowsPerPage,
                  notFound ? 2 : dataSource.data?.length
                )}
              />
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
      {pagination && (
        <TablePagination
          page={page}
          component="div"
          count={10}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          rowsPerPageOptions={pagination.rowsPerPageOptions || [5, 10, 25]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          // hideNextButton={!pagination.hasNextPage}
          // nextIconButtonProps={
          //   !pagination.hasNextPage && {
          //     style: { display: "none" },
          //   }
          // }
          ActionsComponent={(props) => (
            <TablePaginationActions
              {...props}
              hasNextPage={pagination.hasNextPage}
            />
          )}
        />
      )}
    </Card>
  );
};

function TablePaginationActions(
  props: TablePaginationActionsProps & { hasNextPage: boolean }
) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1);
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={!props.hasNextPage}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
    </Box>
  );
}
