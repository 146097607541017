import {
  type Dispatch,
  createContext,
  useEffect,
  useReducer,
  Reducer,
} from "react";
import PropTypes from "prop-types";
// utils
import axios from "../utils/axios";
import { isValidToken, setSession } from "../utils/jwt";
import { IUser } from "helpers/interfaces";
import { API } from "utils/axios/paths";
import storageConfig from "utils/storage";

// ----------------------------------------------------------------------
interface AuthState {
  isAuthenticated: boolean;
  isInitialized: boolean;
  user: IUser | null | undefined;
}

type Itype = "INITIALIZE" | "LOGIN" | "LOGOUT" | "REGISTER";

export interface Actions<T> {
  type: Itype;
  payload: T;
}

interface IReducer {
  (state: AuthState, action: Actions<any>): AuthState;
}

interface RegisterArgs {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  phone: string;
}

interface LoginArgs {
  email: string;
  password: string;
}

interface ContextContent {
  AuthState: AuthState;
  dispatch: Dispatch<Actions<any>>;
  login: (args: LoginArgs) => Promise<void>;
  logout: () => Promise<void>;
  register: (args: RegisterArgs) => Promise<void>;
}

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

interface IHandlers {
  [key: string]: IReducer;
}

const handlers: IHandlers = {
  INITIALIZE: (
    state: AuthState,
    action: Actions<Omit<AuthState, "isInitialized">>
  ) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (
    state: AuthState,
    action: Actions<Omit<AuthState, "isInitialized" | "isAuthenticated">>
  ) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state: AuthState) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (
    state: AuthState,
    action: Actions<Omit<AuthState, "isInitialized" | "isAuthenticated">>
  ) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (
  state: AuthState,
  action: Actions<Partial<AuthState>>
): AuthState =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext<ContextContent>({
  AuthState: initialState,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  dispatch: () => {},
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = storageConfig.getToken();

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const response = await axios.get(API.USER.PROFILE);
          const user = response.data;

          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async ({ email, password }: LoginArgs) => {
    const response = await axios.post(API.AUTH.LOGIN, {
      email,
      password,
    });
    const { access_token } = response?.data;
    setSession(access_token);
    const selfres = await axios.get(API.USER.PROFILE);
    dispatch({
      type: "LOGIN",
      payload: {
        user: selfres.data,
      },
    });
  };

  const register = async ({
    email,
    password,
    firstName,
    lastName,
    phone,
  }: RegisterArgs) => {
    const response = await axios.post(API.AUTH.REGISTER, {
      email,
      password,
      firstname: firstName,
      lastname: lastName,
      phoneNumber: phone,
    });
    const { access_token } = response.data;
    setSession(access_token);

    const self_res = await axios.get(API.USER.PROFILE);
    const user = self_res.data;

    dispatch({
      type: "REGISTER",
      payload: {
        user,
      },
    });
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: "LOGOUT", payload: {} });
  };

  return (
    <AuthContext.Provider
      value={{
        AuthState: state,
        login,
        logout,
        register,
        dispatch,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
