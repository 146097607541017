import { Theme } from "@mui/material";
import { merge } from "lodash";
//====================== overides ========================
import InputLabel from "./InputLabel";
import AppBar from "./AppBar";
import Drawer from "./Drawer";
import Paper from "./Paper";
import Input from "./Input";
import LoadingButton from "./LoadingButton";
import Button from "./Button";
import Card from "./Card";
import Container from "./Container";
import Chip from "./Chip";
import Autocomplete from "./Autocomplete";
import Table from "./Table";
import Popover from "./Popover";
import Slider from "./Slider";

// ==============================|| OVERRIDES - MAIN ||============================== //

export default function ComponentsOverrides(theme: Theme) {
  return merge(
    InputLabel(theme),
    AppBar(theme),
    Drawer(theme),
    Paper(theme),
    Input(theme),
    Button(theme),
    Card(theme),
    LoadingButton(),
    Container(theme),
    Chip(theme),
    Autocomplete(theme),
    Table(theme),
    Popover(theme),
    Slider(theme)
  );
}
