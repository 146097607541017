import { Box, Card, Container, Stack, Typography, Link } from "@mui/material";
import { styled } from "@mui/system";
import { LoginForm } from "components/auth/login";
import Image from "components/Image";
import Page from "components/Page";
import useResponsive from "hooks/useResponsive";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { PATHS } from "routes/paths";

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const mdUp = useResponsive("up", "md");
  const { t } = useTranslation();
  return (
    <Page title="Login">
      <RootStyle>
        {mdUp && (
          <Card
            sx={{
              width: "100%",
              maxWidth: 464,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              margin: (theme) => theme.spacing(2, 0, 2, 2),
            }}
          >
            <Image
              src="https://images.unsplash.com/photo-1543599538-a6c4f6cc5c05?q=80&w=3840&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              alt="login"
              sx={{
                clipPath: "inset(0% 0% 0% 0%)",
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          </Card>
        )}

        <Container maxWidth="sm">
          <ContentStyle>
            <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h4" gutterBottom>
                  {t("signIn.signinto")}
                </Typography>
                <Typography sx={{ color: "text.secondary" }}>
                  {t("signIn.enterDetails")}
                </Typography>
              </Box>
            </Stack>

            <LoginForm />

            <Typography
              variant="body2"
              sx={{ mt: 3, textAlign: { xs: "center", md: "left" } }}
            >
              {t("signIn.dontHaveAcount")} &nbsp;
              <Link
                variant="subtitle2"
                component={RouterLink}
                to={PATHS.AUTH.REGISTER}
              >
                {t("signUp.title")}
              </Link>
            </Typography>
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
