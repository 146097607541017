import useSWR from "swr";
import { API } from "utils/axios/paths";

const useProfilePhoto = () => {
  const response = useSWR(API.USER.PHOTO, {
    refreshInterval: 0,
    errorRetryCount: 4,
  });

  return response;
};

export default useProfilePhoto;
