import { IOffer } from "helpers/interfaces";
import OfferDetailsHero from "./offer-details-hero";
import OfferDetailsBody from "./offer-details-body";
import { Box, Breadcrumbs, Link, Stack, Typography } from "@mui/material";
import { PATHS } from "routes/paths";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const OfferView = ({ offer }: { offer: IOffer }) => {
  const { t } = useTranslation();
  return (
    <Stack spacing={3} sx={{ my: 6 }}>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={
          <Box
            sx={{
              mx: 0.5,
              width: 7,
              height: 7,
              bgcolor: "grey.700",
              borderRadius: "50%",
              border: "1px solid",
              borderColor: "grey.500",
            }}
          />
        }
      >
        <Link component={RouterLink} underline="hover" color="inherit" to="/">
          {t("navbar.home")}
        </Link>
        <Link
          component={RouterLink}
          underline="hover"
          color="inherit"
          to={PATHS.OFFERS.ROOT}
        >
          {t("offers.title")}
        </Link>
        <Typography
          color="text.primary"
          variant="body2"
          sx={{
            // italien
            fontStyle: "italic",
          }}
        >
          "{offer?.name}"
        </Typography>
      </Breadcrumbs>
      <div>
        <OfferDetailsHero offer={offer} />
        <OfferDetailsBody offer={offer} />
      </div>
    </Stack>
  );
};

export default OfferView;
