import { Typography, Card, CardContent, Divider, Stack } from "@mui/material";
import { IOffer } from "helpers/interfaces";
import { useTranslation } from "react-i18next";

interface Props {
  offer: IOffer;
}

function OfferDetailsBody({ offer }: Props) {
  const { t } = useTranslation();
  return (
    <Card
      sx={{
        borderRadius: "0 0 20px 20px",
        border: "1px dashed",
        borderColor: "primary.light",
        pt: 4,
      }}
    >
      <CardContent>
        <Typography variant="subtitle1" color="text.primary">
          {t("offers.description")}
        </Typography>

        <Typography variant="body1" color="text.secondary" paragraph>
          {offer.description}
        </Typography>

        <Divider />

        <Typography variant="subtitle1" color="text.primary" mt={2}>
          {t("offers.requirements")}
        </Typography>
        <Typography variant="body1" color="text.secondary" paragraph>
          {offer.requirements}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default OfferDetailsBody;
