import { API } from "utils/axios/paths";

interface ErrorHandler {
  [key: string]: {
    [key: number]: string;
  };
}

const errorHandler: ErrorHandler = {
  [API.AUTH.LOGIN]: {
    401: "messages.emailOrPasswordIncorrect",
  },
};

export default errorHandler;
