// ----------------------------------------------------------------------

import { Theme } from "@mui/material";

export default function Popover(theme: Theme) {
  return {
    MuiPopover: {
      styleOverrides: {
        paper: {
          boxShadow: theme.shadows[10],
          borderRadius: Number(theme.shape.borderRadius) * 2,
        },
      },
    },
  };
}
