import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import { useTheme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import useResponsive from "hooks/useResponsive";
import MenuIcon from "@mui/icons-material/Menu";
import LanguagePopover from "layouts/header/languagePopover";
import AccountPopover from "layouts/header/AccountPopover";
import { bgBlur } from "theme/css";
import { HEADER, NAV } from "./config-layout";
import { AppBar } from "@mui/material";

export default function Header({ onOpenNav }: { onOpenNav: VoidFunction }) {
  const theme = useTheme();

  const lgUp = useResponsive("up", "lg");

  const renderContent = (
    <>
      {!lgUp && (
        <IconButton onClick={onOpenNav} sx={{ mr: 1 }}>
          <MenuIcon />
        </IconButton>
      )}

      {/* <Searchbar /> */}

      <Box sx={{ flexGrow: 1 }} />

      <Stack direction="row" alignItems="center" spacing={1}>
        <LanguagePopover />
        <AccountPopover />
      </Stack>
    </>
  );

  return (
    <AppBar
      component={"nav"}
      sx={{
        boxShadow: "none",
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,

        borderBottom: (theme) => `dashed 1px ${theme.palette.divider}`,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(["height"], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(lgUp && {
          width: `calc(100% - ${NAV.WIDTH + 1}px)`,
          height: HEADER.H_DESKTOP,
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}
